import { PrescriptionInfo } from '@shared/types'

/**
 * Ideally we render something akin to `8mg, 10 days supply`. In the
 * event that only one of those values exists, we just return that.
 */
export const getSupplyText = (prescription: PrescriptionInfo) => {
  const base = prescription.strength === '-' ? '' : prescription.strength

  if (prescription.daysSupply) {
    if (!base || base.length === 0) {
      return `${prescription.daysSupply} days supply`
    }
    return `${base}, ${prescription.daysSupply} days supply`
  }
  return base
}
