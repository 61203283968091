export const Config = Object.freeze({
  NODE_ENV: process.env.NODE_ENV || 'development',
  API_BASE_URL: process.env.API_BASE_URL || '',
  MY_BASE_URL: process.env.MY_BASE_URL || '',
  APPLICATION_BASE_URL: process.env.APPLICATION_BASE_URL || '',
  ACUITY_INTAKE_SCHEDULING_URL: process.env.ACUITY_INTAKE_SCHEDULING_URL || '',
  FIREBASE_API_KEY: process.env.FIREBASE_API_KEY || '',
  FIREBASE_AUTH_DOMAIN: process.env.FIREBASE_AUTH_DOMAIN || '',
  FIREBASE_PROJECT_ID: process.env.FIREBASE_PROJECT_ID || '',
  FIREBASE_STORAGE_BUCKET: process.env.FIREBASE_STORAGE_BUCKET || '',
  FIREBASE_MESSAGING_SENDER_ID: process.env.FIREBASE_MESSAGING_SENDER_ID || '',
  FIREBASE_APP_ID: process.env.FIREBASE_APP_ID || '',
  FULLSTORY_ORG_ID: process.env.FULLSTORY_ORG_ID || '',
  LAUNCH_DARKLY_CLIENT_ID: process.env.LAUNCH_DARKLY_CLIENT_ID || '',
  IMAGE_BASE_URL: process.env.IMAGE_BASE_URL || '',
  ACHIEVEMENTS_BASE_URL: process.env.ACHIEVEMENTS_BASE_URL || '',
  REFERRAL_PARTNER_ASSETS_BASE_URL: process.env.REFERRAL_PARTNER_ASSETS_BASE_URL || '',
  REACT_APP_RS_WRITE_KEY: process.env.REACT_APP_RS_WRITE_KEY || '',
  REACT_APP_RS_DATA_PLANE_URL: process.env.REACT_APP_RS_DATA_PLANE_URL || '',
  GITHUB_SHA: process.env.REACT_APP_GITHUB_SHA || '',
  GA_MEASUREMENT_ID: process.env.GA_MEASUREMENT_ID || '',
  APP_VERIFICATION_DISABLED_FOR_TESTING:
    process.env.APP_VERIFICATION_DISABLED_FOR_TESTING === 'true',
  FONT_CDN_BASE_URL: process.env.FONT_CDN_BASE_URL,
  STRIPE_PUBLISHABLE_KEY: process.env.STRIPE_PUBLISHABLE_KEY || '',
  CUSTOMER_IO_SITE_ID: process.env.CUSTOMER_IO_SITE_ID || '',
  OPHELIA_PHONE_NUMBER: process.env.OPHELIA_PHONE_NUMBER || '',
  RECAPTCHA_ENTERPRISE_SITE_KEY: process.env.RECAPTCHA_ENTERPRISE_SITE_KEY || '',
  // The debug token cannot be empty - it must be the token itself or true to enable the debug token or false to disable it
  FIREBASE_APPCHECK_DEBUG_TOKEN: process.env.FIREBASE_APPCHECK_DEBUG || false,
})
