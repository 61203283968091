import { z } from 'zod'

export const MESSAGE_QUEUE_API_KEY_HEADER = 'cloud-tasks-api-key' as const

export const MessageSchema = z.discriminatedUnion('name', [
  z.object({
    name: z.literal('appointment-reminder'),
    scheduleTimeUnix: z.null(),
    payload: z.object({
      hoursBefore: z.string().pipe(z.coerce.number().positive()),
      appointmentOid: z.string(),
    }),
  }),
  z.object({
    name: z.literal('drop-in-clinic-reminder'),
    scheduleTimeUnix: z.number().positive(),
    payload: z.object({
      patientId: z.string(),
      clinicId: z.string(),
    }),
  }),
])
