import { z } from 'zod'
import { DAYS_READONLY } from '../../../account'
import { ApiSchemas } from '../../utils'

const PostAvailabilityReqResSchemas = {
  req: z.object({
    params: z.object({
      patientId: z.string(),
    }),
    body: z.object({
      availability: z.object({
        morning: z.array(z.enum(DAYS_READONLY)),
        afternoon: z.array(z.enum(DAYS_READONLY)),
        evening: z.array(z.enum(DAYS_READONLY)),
      }),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

const GetAvailabilityReqResSchemas = {
  req: z.object({
    params: z.object({
      patientId: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      availability: z
        .object({
          morning: z.array(z.enum(DAYS_READONLY)),
          afternoon: z.array(z.enum(DAYS_READONLY)),
          evening: z.array(z.enum(DAYS_READONLY)),
        })
        .nullable(),
    }),
  }),
}

export const MyAvailabilityApi = {
  'POST /patients/:patientId/settings/availability': PostAvailabilityReqResSchemas,
  'GET /patients/:patientId/settings/availability': GetAvailabilityReqResSchemas,
} satisfies ApiSchemas
