import {
  Divider,
  NavigateExternal,
  PrimaryButton,
  Stack,
  Text,
  TitleTwo,
  useBanner,
  useLifecycle,
} from '@shared/components'
import { PATIENT_URL_SEARCH_PARAM, WELLNESS_CHECK_SEARCH_PARAMS } from '@shared/types'
import Lottie from 'lottie-react'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import * as ZoomLoadingAnimation from '../assets/zoom_loading.json'
import { ContactSupport } from '../common/components'
import { ShareReferralLinkButtons } from '../common/components/ShareReferralLinkButtons'
import * as FullStory from '../common/fullstory'
import { useOnboardingDims, usePortalDims, usePortalQuery, useQueryParams } from '../common/hooks'
import { sendPageEvent, sendTrackEvent } from '../common/rudderstack'
import { FinancialConsentBeforeZoom } from './FinancialConsentBeforeZoom'
import { TpoConsentBeforeZoom } from './TpoConsentBeforeZoom'

const WellnessCheckRedirect = ({
  wellnessCheckUrl,
  redirectUrl,
}: {
  wellnessCheckUrl: string
  redirectUrl: string
}) => {
  const { showBanner } = useBanner()
  const { isMobile } = usePortalDims()
  const url = new URL(wellnessCheckUrl)
  // Include the zoom url as a query param so that the wellness check can redirect to zoom upon completion.
  url.searchParams.set(WELLNESS_CHECK_SEARCH_PARAMS.ZoomUrl, redirectUrl)

  useLifecycle({
    onMount: () => {
      FullStory.event('MBC Before Zoom', { wellnessCheckUrl, redirectUrl })
      showBanner({
        label: 'Wellness assessment not completed',
        type: 'warning',
      })
    },
  })

  return (
    <Stack align='center' spacing='lg'>
      <TitleTwo>Please answer a few questions before you see your clinician</TitleTwo>
      <Text>
        Your answers will help us provide you with supportive care that&apos;s tailored to your
        unique needs. Once completed, we&apos;ll automatically connect you to the Zoom meeting.
      </Text>
      <Text>
        <Text bold>The assessment should only take a minute.</Text> Your clinician is informed and
        will be waiting for you.
      </Text>
      <PrimaryButton
        fullWidth={isMobile}
        component={Link}
        to={{
          pathname: url.pathname,
          search: url.search.toString(),
        }}
      >
        Start wellness assessment
      </PrimaryButton>
      <ContactSupport />
    </Stack>
  )
}

const StandbyLinkExpired = ({
  message,
  originalStartTime,
}: {
  message: string
  originalStartTime: string
}) => {
  useLifecycle({
    onMount: () => {
      FullStory.event('Rendered Standby Link Expired Message')
    },
  })

  return (
    <Stack spacing='lg'>
      <TitleTwo>{message}</TitleTwo>
      <Text>{originalStartTime}</Text>
      <ContactSupport />
    </Stack>
  )
}

export const ZoomRedirectApp = () => {
  const { meetingId = '' } = useParams()
  const query = useQueryParams()
  const password = query.get('pwd')
  const standbyDatetime = query.get(PATIENT_URL_SEARCH_PARAM.StandbyDatetime.key)
  const { isMobile } = useOnboardingDims()
  const { showBanner } = useBanner()

  useLifecycle({
    onMount: () => {
      sendPageEvent('Zoom Redirect Page', { meetingId: meetingId ?? '' })
      sendTrackEvent('Zoom Redirect Page Visited', { meetingId: meetingId ?? '' })
      FullStory.event('Zoom Redirect Page Visited', { meetingId: meetingId ?? '' })
    },
  })

  const zoomLinkQuery = usePortalQuery(
    'GET /meeting/:meetingId',
    {
      params: {
        meetingId,
      },
      query: {
        password: password ?? '',
        standbyDatetime: standbyDatetime ?? '',
      },
    },
    {
      onError: error => {
        showBanner({
          type: 'error',
          label:
            'There was an error fetching your meeting details. Please reach out to Ophelia for support.',
        })
        sendTrackEvent('Zoom Redirect Page Error', { error: `${error}` })
        FullStory.event('Zoom Redirect Page Error', { meetingId })
      },
    },
  )

  const { data, isLoading } = zoomLinkQuery
  const { zoomUrl, wellnessCheckUrl, financialConsentToken, tpoConsentToken, standbyException } =
    data || {}

  /**
   * NOTE: Must render StandbyLinkExpired before the zoom redirect.
   * Indicates that the appointment can no longer be seen at this time.
   */
  if (standbyException) {
    return (
      <StandbyLinkExpired
        message={standbyException.message}
        originalStartTime={standbyException.originalStartTime}
      />
    )
  }

  if (zoomUrl) {
    if (tpoConsentToken) {
      return <TpoConsentBeforeZoom token={tpoConsentToken} redirectUrl={location.href} />
    }

    if (wellnessCheckUrl) {
      return (
        <WellnessCheckRedirect wellnessCheckUrl={wellnessCheckUrl} redirectUrl={location.href} />
      )
    }

    if (financialConsentToken) {
      return (
        <FinancialConsentBeforeZoom token={financialConsentToken} redirectUrl={location.href} />
      )
    }

    return <NavigateExternal url={zoomUrl} with='replace' />
  }

  return (
    <Stack spacing='lg'>
      {isLoading && (
        <>
          <Stack>
            <TitleTwo>Launching Zoom</TitleTwo>
            <Text>Select “Open” or “Launch Meeting” if prompted by Zoom.</Text>
          </Stack>
          <Lottie
            style={{ width: '5rem', alignSelf: 'center' }}
            animationData={ZoomLoadingAnimation}
          />
          <ContactSupport />
        </>
      )}
      <Divider />
      <Stack>
        <TitleTwo>Refer friends, save lives</TitleTwo>
        <Text>
          Share Ophelia with a friend or loved one who isn&apos;t in treatment yet and tell them you
          can do it together.
        </Text>
        <ShareReferralLinkButtons
          source='zoom_redirect'
          orientation={isMobile ? 'vertical' : 'horizontal'}
        />
      </Stack>
    </Stack>
  )
}
