import { DatabaseMetadata, EMRTaskType } from '..'

export type EMRNotificationType =
  | 'discharged_patient'
  | 'tagged_in_note'
  | 'queued_bridge_prescription'
  | 'resend_prescription'
  | 'general_refill_request'
  | 'medical_concern'

export type EMRNotificationModelContext<T extends EMRNotificationType = EMRNotificationType> =
  T extends 'discharged_patient'
    ? {
        patientId: string
        triggerEmployeeId: string
      }
    : T extends 'tagged_in_note'
    ? {
        patientId: string | null
        triggerEmployeeId: string
        taskType: EMRTaskType
        taskId: string
      }
    : T extends 'queued_bridge_prescription'
    ? {
        patientId: string
        triggerEmployeeId: string
        taskType: EMRTaskType
        taskId: string
      }
    : T extends 'resend_prescription'
    ? {
        patientId: string
        triggerEmployeeId: string
        taskType: EMRTaskType
        taskId: string
      }
    : T extends 'general_refill_request'
    ? {
        patientId: string
        triggerEmployeeId: string
        taskType: EMRTaskType
        taskId: string
      }
    : T extends 'medical_concern'
    ? {
        patientId: string
        triggerEmployeeId: string
        taskType: EMRTaskType
        taskId: string
      }
    : // eslint-disable-next-line @typescript-eslint/ban-types
      {}

export type EMRNotificationModel<T extends EMRNotificationType = EMRNotificationType> = {
  createdAt: string
  seenAt: string | null
  employeeId: string
  type: T
  context: EMRNotificationModelContext<T>
}

export type EMRNotification<T extends EMRNotificationType> = EMRNotificationModel<T> &
  DatabaseMetadata

export type EMRNotificationResponse = {
  oid: string
  createdAt: string
  message: string
  url: string
  type: EMRNotificationType
  seen: boolean
}

export const generateNotificationUrl = <T extends EMRNotificationType = EMRNotificationType>({
  notification,
}: {
  notification: EMRNotification<T>
}) => {
  switch (notification.type) {
    case 'discharged_patient': {
      const context = notification.context as EMRNotificationModelContext<'discharged_patient'>
      return `/patients/${context.patientId}`
    }
    case 'tagged_in_note': {
      const context = notification.context as EMRNotificationModelContext<'tagged_in_note'>
      return `/issues/open?taskId=${context.taskId}`
    }
    case 'queued_bridge_prescription': {
      const context =
        notification.context as EMRNotificationModelContext<'queued_bridge_prescription'>
      return `/issues/open?taskId=${context.taskId}`
    }
    case 'general_refill_request': {
      const context = notification.context as EMRNotificationModelContext<'general_refill_request'>
      return `/issues/open?taskId=${context.taskId}`
    }
    case 'resend_prescription': {
      const context = notification.context as EMRNotificationModelContext<'resend_prescription'>
      return `/issues/open?taskId=${context.taskId}`
    }
    case 'medical_concern': {
      const context = notification.context as EMRNotificationModelContext<'medical_concern'>
      return `/issues/open?taskId=${context.taskId}`
    }
    default:
      return ''
  }
}

// NOTE: legacy notification model, which we can rename to `LegacyNotificationModel` once shift to using the new model
export type NotificationModel = {
  icon: string
  message: string
  notificationId?: string
  notificationTimezone: string
  recipient: string
  timestampCreated: string
  timestampMarkedAsSeen?: string
  title: string
  triggeredById: string
  triggeredByType: string
  url: string
}

export type Notification = NotificationModel & DatabaseMetadata
