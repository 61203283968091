import { Box, Grid, Space, Text } from '@shared/components'
import { PrescriptionInfo } from '@shared/types'
import { dayjs } from '@shared/utils'
import React from 'react'
import { usePortalDims } from '../../../common/hooks'
import { getSupplyText } from './utils'

export const PastPrescriptionEntry = ({ prescription }: { prescription: PrescriptionInfo }) => {
  const { desktopMediaQuery } = usePortalDims()

  return (
    <Box
      sx={theme => ({
        borderWidth: theme.other.sizes.border.md,
        borderStyle: 'solid',
        borderColor: theme.colors.gray[7],
        borderRadius: theme.radius.sm,
        padding: theme.spacing.md,
      })}
    >
      <Text bold transform='capitalize'>
        {prescription.genericDrugName}
      </Text>
      <Space h='md' />
      <Grid columns={12}>
        <Grid.Col span={12} md={4}>
          <Text transform='capitalize'>{prescription.doseForm}</Text>
        </Grid.Col>
        <Grid.Col span={12} md={4}>
          <Text>{getSupplyText(prescription)}</Text>
        </Grid.Col>
        <Grid.Col span={12} md={4}>
          <Box
            sx={() => ({
              [desktopMediaQuery]: {
                display: 'flex',
                justifyContent: 'flex-end',
              },
            })}
          >
            <Text>
              {`Prescribed on ${dayjs(prescription?.writtenDate)
                .format('MMM D YYYY')
                .toUpperCase()}`}
            </Text>
          </Box>
        </Grid.Col>
      </Grid>
    </Box>
  )
}
