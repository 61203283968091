import { Box, Pill, PrimaryButton, Text } from '@shared/components'
import { PrescriptionInfo as PrescriptionInfoType } from '@shared/types'
import { dayjs, phone } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import React from 'react'
import { useQuery } from 'react-query'
import { patientApi } from '../../../common/api'
import { CardChecklist } from '../../../common/components'
import { usePortalDims } from '../../../common/hooks'
import { sendTrackEvent } from '../../../common/rudderstack'
import { getSupplyText } from './utils'

export const PrescriptionPill = ({ prescription }: { prescription: PrescriptionInfoType }) => {
  return <Pill status='success'>{prescription.displayStatus}</Pill>
}

export const PrescriptionInfo = ({ prescription }: { prescription: PrescriptionInfoType }) => {
  const { isMobile } = usePortalDims()

  const { isLoading, data } = useQuery(
    ...patientApi.getQuery('GET /pharmacy/find', {
      query: { pharmacyId: prescription.pharmacyId.toString() },
    }),
  )

  return (
    <>
      <CardChecklist
        list={[
          <Box key='dosage'>{capitalize(prescription.doseForm || '')}</Box>,
          getSupplyText(prescription),
        ]}
      />
      <Text mt='sm'>
        {`Prescribed on ${dayjs(prescription.writtenDate).format('MMM D YYYY').toUpperCase()}`}
      </Text>
      <PrimaryButton
        loading={isLoading}
        component='a'
        onClick={() => sendTrackEvent('Call Pharmacy Clicked')}
        href={data?.PrimaryPhone && `tel:${phone(data?.PrimaryPhone).normalized}`}
        fullWidth={isMobile}
        mt='md'
      >
        Call your pharmacy
      </PrimaryButton>
    </>
  )
}
