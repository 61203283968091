// eslint-disable-next-line no-restricted-imports
import { Flex, FlexProps, useMantineTheme } from '@mantine/core'
import React from 'react'
import { TertiaryButton } from '../buttons'
import { BookmarkIcon, CheckCircleIcon, SlashIcon, XIcon } from '../icons'
import { Text } from '../typography'

export type BannerType = 'error' | 'warning' | 'success'
export type BannerVariant = 'static' | 'action' | 'descriptive'

function getIcon({ type }: { type: BannerType }) {
  switch (type) {
    case 'error':
      return <SlashIcon />
    case 'warning':
      return <BookmarkIcon />
    case 'success':
      return <CheckCircleIcon />
  }
}

export type BannerProps = Omit<FlexProps, 'styles' | 'sx'> & {
  label: string
  description?: string
  type: BannerType
  variant?: BannerVariant
  icon?: React.ReactNode
  rightIcon?: React.ReactNode
  onClick?: (() => void) | undefined
}

export const Banner = ({
  label,
  description,
  type,
  variant = 'static',
  icon,
  rightIcon,
  onClick,
  ...props
}: BannerProps) => {
  const {
    other: { colors, sizes },
  } = useMantineTheme()

  return (
    <Flex
      bg={props.bg || colors[type][1]}
      p={sizes.padding.lg}
      direction='column'
      gap={sizes.gap.md}
      {...props}
    >
      <Flex
        align='center'
        justify={onClick ?? variant !== 'static' ? 'space-between' : 'center'}
        gap={sizes.gap.md}
      >
        <Flex gap={sizes.gap.md} align='center' style={{ color: colors[type][0] }}>
          {icon ? icon : getIcon({ type })}
          <Text bold color={colors.text[0]}>
            {label}
          </Text>
        </Flex>
        {onClick ? (
          <TertiaryButton
            backgroundColor={(props.bg as `#${string}`) || colors[type][1]}
            color={colors.text[0]}
            size='sm'
            onClick={onClick}
            leftIcon={<XIcon />}
          />
        ) : (
          rightIcon
        )}
      </Flex>
      {description && <Text color={colors.text[0]}>{description}</Text>}
    </Flex>
  )
}
