var supportsPassiveEvents;
function arePassiveEventsSupported() {
  if (supportsPassiveEvents !== undefined) {
    return supportsPassiveEvents;
  }

  var passive = false;
  var options = {
    // @ts-ignore: this is a temporary object, it doesn't have to return anything
    get passive() {
      passive = true;
    }

  };

  var noop = function noop() {};

  window.addEventListener('t', noop, options);
  window.removeEventListener('t', noop, options);
  supportsPassiveEvents = passive;
  return passive;
}

export default arePassiveEventsSupported;
