import { RemoteConfigEntries } from '@shared/types'
import { toTime } from '@shared/utils'
import { initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaEnterpriseProvider, getToken } from 'firebase/app-check'
import { browserLocalPersistence, getAuth, User } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config'
import { Config } from './config'

export const app = initializeApp({
  apiKey: Config.FIREBASE_API_KEY,
  authDomain: Config.FIREBASE_AUTH_DOMAIN,
  projectId: Config.FIREBASE_PROJECT_ID,
  storageBucket: Config.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: Config.FIREBASE_MESSAGING_SENDER_ID,
  appId: Config.FIREBASE_APP_ID,
})

/**
 * App Check
 */
window.FIREBASE_APPCHECK_DEBUG_TOKEN = Config.FIREBASE_APPCHECK_DEBUG_TOKEN
export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(Config.RECAPTCHA_ENTERPRISE_SITE_KEY),
  isTokenAutoRefreshEnabled: true,
})

export const getAppCheckToken = () => getToken(appCheck)

/**
 * Auth
 */

export type FirebaseUser = User

const authInstance = getAuth(app)

export { onAuthStateChanged, signInAnonymously } from 'firebase/auth'

/*
 * Ensures that the auth's persistence is set to SESSION only.
 * This is critical, as we do not want to leak PHI on shared devices.
 */
void authInstance.setPersistence(browserLocalPersistence)

authInstance.settings.appVerificationDisabledForTesting =
  Config.APP_VERIFICATION_DISABLED_FOR_TESTING

export const auth = authInstance

/**
 * Remote config
 */

const remoteConfig = getRemoteConfig(app)

if (Config.NODE_ENV === 'production') {
  // There are potential rate limiting concerns and would like to avoid fetching more than 5x per hour, per client
  remoteConfig.settings.minimumFetchIntervalMillis = toTime('12 minutes').ms()
} else {
  // In dev, we want to be able to test remote config changes more quickly
  remoteConfig.settings.minimumFetchIntervalMillis = toTime('5 minutes').ms()
}

export const fetchAndActivateRemoteConfig = () => fetchAndActivate(remoteConfig)

export const getRemoteConfigValue = (key: keyof RemoteConfigEntries) => {
  return getValue(remoteConfig, key)
}

/**
 * Firestore
 */

export const db = getFirestore(app)

export { addDoc, collection, doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore'
