import { useForm } from '@mantine/form'
import {
  Box,
  Group,
  PrimaryButton,
  Select,
  Text,
  Textarea,
  TitleThree,
  useLifecycle,
} from '@shared/components'
import React, { forwardRef } from 'react'
import { usePortalDims, usePortalMutation } from '../../common/hooks'
import {
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem,
} from '../../common/storage'

const SurveyData = [
  {
    label: 'I’m at work',
    value: 'at_work',
  },
  { label: 'I’m not in a private setting', value: 'not_in_private_setting' },
  {
    label: 'I’m not feeling ready to talk to someone yet',
    value: 'not_ready_to_talk',
  },
  {
    label: 'I’m exploring other treatment options',
    value: 'exploring_other_options',
  },
  { label: 'Something else...', value: 'other' },
] as const

type SurveyFormType = {
  response: string
  freeForm: string
}
export const ScheduledForLaterSurvey = () => {
  const { isMobile } = usePortalDims()
  const postSegmentEvent = usePortalMutation('POST /segment-events')

  useLifecycle({
    onUnmount: () => {
      const surveyStorageItem = getSessionStorageItem(
        'scheduled_for_later_while_cmn_available_survey',
      )
      if (surveyStorageItem === 'submitted') {
        removeSessionStorageItem('scheduled_for_later_while_cmn_available_survey')
      }
    },
  })

  const surveyForm = useForm<SurveyFormType>({
    validateInputOnBlur: false,
    initialValues: {
      response: '',
      freeForm: '',
    },
    validate: values => {
      const requireFreeform = values.response === 'other'

      if (requireFreeform) {
        return {
          response: values.response ? null : 'Please select one',
          freeForm: values.freeForm ? null : 'Required',
        }
      }

      return {
        response: values.response ? null : 'Please select one',
        freeForm: null,
      }
    },
  })

  const showSubmit = surveyForm.values.response

  const enableSubmit =
    surveyForm.values.response !== 'other' ||
    (surveyForm.values.freeForm && surveyForm.values.freeForm.length > 5)

  const handleSubmit = async () => {
    const { hasErrors } = surveyForm.validate()

    if (hasErrors) {
      return
    }

    await postSegmentEvent.mutateAsync(
      {
        data: {
          event: 'Scheduled For Later Survey Submitted',
          properties: { ...surveyForm.values },
        },
      },
      {
        onSuccess: () => {
          setSessionStorageItem('scheduled_for_later_while_cmn_available_survey', 'submitted')
        },
      },
    )
  }

  if (getSessionStorageItem('scheduled_for_later_while_cmn_available_survey') === 'submitted') {
    return <TitleThree>Thank you for your response!</TitleThree>
  }

  return (
    <>
      <TitleThree>Tell us why you scheduled for later</TitleThree>
      <Select
        disabled={postSegmentEvent.isLoading}
        aria-label='Tell us why you scheduled for later'
        clearable
        placeholder='Select one...'
        data={SurveyData}
        maxDropdownHeight={275}
        itemComponent={SurveySelectItem}
        {...surveyForm.getInputProps('response')}
      />
      {surveyForm.values.response === 'other' && (
        <Textarea
          disabled={postSegmentEvent.isLoading}
          aria-label='Please tell us more'
          placeholder='Please tell us more...'
          maxLength={75}
          autosize
          {...surveyForm.getInputProps('freeForm')}
        />
      )}
      {showSubmit && (
        <PrimaryButton
          onClick={() => handleSubmit()}
          disabled={!enableSubmit}
          loading={postSegmentEvent.isLoading}
          fullWidth={isMobile}
        >
          Submit
        </PrimaryButton>
      )}
    </>
  )
}

type SurveySelectItemProps = {
  value: string
  description: string
  label: string
}

export const SurveySelectItem = forwardRef<HTMLDivElement, SurveySelectItemProps>(
  ({ ...others }, ref) => {
    const { label, ...props } = others

    return (
      <Box {...props} ref={ref} style={{ width: '100%' }}>
        <Group position='apart' sx={{ width: '100%' }}>
          <Text w='100%' style={{ whiteSpace: 'pre-wrap' }}>
            {label}
          </Text>
        </Group>
      </Box>
    )
  },
)

SurveySelectItem.displayName = 'SurveySelectItem'
