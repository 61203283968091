import { useBanner, useLifecycle } from '@shared/components'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useAuth } from '../common/hooks'
import { routes } from '../common/routes'
import * as Pages from './pages'

export const PortalApp = () => {
  const { currentUser } = useAuth()
  const { showBanner } = useBanner()
  const patientStatus = currentUser?.data?.statuses?.patient
  const isPatientDischarged = patientStatus === 'discharged'

  useLifecycle({
    onMount: () => {
      if (isPatientDischarged) {
        showBanner({
          type: 'warning',
          label: 'Your treatment has been discontinued',
        })
      }
    },
  })

  return (
    <Routes>
      <Route index element={<Pages.Dashboard />} />
      <Route path={routes.portal.children.account} element={<Pages.Account />} />
      <Route path={routes.portal.children.achievements} element={<Pages.AchievementsPage />} />
      <Route path={routes.portal.children.visits} element={<Pages.Visits />} />
      <Route path={routes.portal.children.prescriptions} element={<Pages.Prescriptions />} />
      <Route path={routes.portal.children.referrals} element={<Pages.Referrals />} />
      <Route path={routes.portal.children.billing.index} element={<Pages.Billing />} />
      <Route
        path={`${routes.portal.children.billing.index}/${routes.portal.children.billing.children.paymentMethods.index}${routes.portal.children.billing.children.paymentMethods.children.add}`}
        element={<Pages.AddPaymentMethod />}
      />
      <Route
        path={`${routes.portal.children.billing.index}/${routes.portal.children.billing.children.paymentMethods.index}${routes.portal.children.billing.children.paymentMethods.children.remove}`}
        element={<Pages.RemovePaymentMethod />}
      />
      <Route
        path={`${routes.portal.children.billing.index}/${routes.portal.children.billing.children.paymentMethods.index}${routes.portal.children.billing.children.paymentMethods.children.edit}`}
        element={<Pages.EditPaymentMethod />}
      />
      <Route
        path={
          routes.portal.children.billing.index + routes.portal.children.billing.children.addPayment
        }
        element={<Pages.AddPayment />}
      />

      <Route
        path={routes.portal.children.intakeVisitStandbyList}
        element={<Pages.StandbyListTask />}
      />
      <Route
        path={
          routes.portal.children.reschedule.index +
          routes.portal.children.reschedule.children.intake
        }
        element={<Pages.RescheduleIntakeVisit />}
      />
      <Route
        path={routes.portal.children.reschedule.index}
        element={
          <Navigate replace to={`${routes.portal.index}/${routes.portal.children.visits}`} />
        }
      />
      <Route
        path={
          routes.portal.children.reschedule.index +
          routes.portal.children.reschedule.children.appointment
        }
        element={<Pages.RescheduleVisit />}
      />

      <Route path={routes.portal.children.documents.index} element={<Pages.Documents />} />
      <Route
        path={
          routes.portal.children.documents.index +
          routes.portal.children.documents.children.financialAgreement
        }
        element={<Pages.FinancialAgreementPage />}
      />
      <Route
        path={
          routes.portal.children.documents.index +
          routes.portal.children.documents.children.treatmentConsent
        }
        element={<Pages.TreatmentConsentPage />}
      />
      <Route
        path={
          routes.portal.children.documents.index +
          routes.portal.children.documents.children.releaseOfInformation
        }
        element={<Pages.ReleaseOfInformationForm />}
      />
      {/* TASKS */}
      <Route
        path={
          routes.portal.children.task.index + routes.portal.children.task.children.emergencyContact
        }
        element={<Pages.EmergencyContactTask />}
      />
      <Route
        path={routes.portal.children.task.index + routes.portal.children.task.children.insurance}
        element={<Pages.InsuranceTask />}
      />
      <Route
        path={
          routes.portal.children.task.index + routes.portal.children.task.children.medicalIntakeForm
        }
        element={<Pages.MedicalIntakeFormTask />}
      />
      <Route
        path={
          routes.portal.children.task.index +
          routes.portal.children.task.children.medicalIntakeForm +
          routes.portal.children.task.workflowPrompt
        }
        element={<Pages.MedicalIntakeFormTask />}
      />
      <Route
        path={routes.portal.children.task.index + routes.portal.children.task.children.zoom}
        element={<Pages.ZoomTask />}
      />
      <Route
        path={routes.portal.children.task.index + routes.portal.children.task.children.phq9Form}
        element={<Pages.Phq9FormTask />}
      />
      <Route
        path={
          routes.portal.children.task.index +
          routes.portal.children.task.children.phq9Form +
          routes.portal.children.task.workflowPrompt
        }
        element={<Pages.Phq9FormTask />}
      />
      <Route
        path={routes.portal.children.task.index + routes.portal.children.task.children.phq8Form}
        element={<Pages.Phq8FormTask />}
      />
      <Route
        path={
          routes.portal.children.task.index +
          routes.portal.children.task.children.phq8Form +
          routes.portal.children.task.workflowPrompt
        }
        element={<Pages.Phq8FormTask />}
      />
      <Route
        path={routes.portal.children.task.index + routes.portal.children.task.children.gad7Form}
        element={<Pages.Gad7FormTask />}
      />
      <Route
        path={
          routes.portal.children.task.index +
          routes.portal.children.task.children.gad7Form +
          routes.portal.children.task.workflowPrompt
        }
        element={<Pages.Gad7FormTask />}
      />
      <Route
        path={routes.portal.children.task.index + routes.portal.children.task.children.barc10Form}
        element={<Pages.Barc10FormTask />}
      />
      <Route
        path={
          routes.portal.children.task.index +
          routes.portal.children.task.children.barc10Form +
          routes.portal.children.task.workflowPrompt
        }
        element={<Pages.Barc10FormTask />}
      />
      <Route
        path={routes.portal.children.task.index + routes.portal.children.task.children.profile}
        element={<Pages.ProfileTask />}
      />
      <Route
        path={
          routes.portal.children.task.index + routes.portal.children.task.children.emergencyContact
        }
        element={<Pages.EmergencyContactTask />}
      />
      <Route
        path={routes.portal.children.task.index + routes.portal.children.task.children.billing}
        element={<Pages.PaymentInfoAndFinancialAgreementTask />}
      />
      <Route
        path={
          routes.portal.children.task.index +
          routes.portal.children.task.children.financialAgreement
        }
        element={<Pages.FinancialAgreementTask />}
      />
      <Route
        path={routes.portal.children.task.index + routes.portal.children.task.children.hdyhau}
        element={<Pages.HDYHAUTask />}
      />
      <Route
        path={routes.portal.children.task.index + routes.portal.children.task.children.uploadId}
        element={<Pages.UploadIdTask />}
      />
      <Route
        path={
          routes.portal.children.task.index + routes.portal.children.task.children.preferredPharmacy
        }
        element={<Pages.PreferredPharmacyTask />}
      />
      <Route
        path={
          routes.portal.children.task.index +
          routes.portal.children.task.children.scheduleIntakeVisit
        }
        element={<Pages.ScheduleIntakeVisitTask />}
      />
      <Route
        path={
          routes.portal.children.task.index +
          routes.portal.children.task.children.scheduleFollowUpVisit
        }
        element={<Pages.ScheduleFollowUpVisitTask />}
      />
      <Route
        path={
          routes.portal.children.task.index + routes.portal.children.task.children.treatmentConsent
        }
        element={<Pages.TreatmentConsentTask />}
      />
      <Route
        path={
          routes.portal.children.task.index +
          routes.portal.children.task.children.treatmentConsentAndFinancialAgreement
        }
        element={<Pages.TreatmentConsentAndFinancialAgreementTask />}
      />
      <Route
        path={routes.portal.children.task.index + routes.portal.children.task.children.tpoConsent}
        element={<Pages.TpoConsentTask />}
      />
      <Route
        path={
          routes.portal.children.task.index + routes.portal.children.task.children.asqForm.index
        }
        element={<Pages.AsqFormTask />}
      />
      <Route
        path={
          routes.portal.children.task.index +
          routes.portal.children.task.children.asqForm.index +
          routes.portal.children.task.workflowPrompt
        }
        element={<Pages.AsqFormTask />}
      />
      <Route
        path={routes.portal.children.task.index + routes.portal.children.task.children.thankYouNote}
        element={<Pages.ThankYouNoteTask />}
      />
      <Route
        path={
          routes.portal.children.task.index +
          routes.portal.children.task.children.asqForm.index +
          routes.portal.children.task.children.asqForm.children.message
        }
        element={<Pages.AsqMessage />}
      />
      <Route
        path={`${routes.portal.children.task.index}/${routes.portal.children.intakeVisitStandbyList}`}
        element={
          <Navigate
            replace
            to={`${routes.portal.index}/${routes.portal.children.intakeVisitStandbyList}`}
          />
        }
      />
      <Route path='*' element={<Navigate replace to='/' />} />
    </Routes>
  )
}
