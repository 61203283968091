import { z } from 'zod'
import { AcuityBlock, AcuityTimeSlot } from './acuity'
import {
  AddressData,
  EligibleInsuranceData,
  FormSubmission,
  FormType,
  InsuranceData,
  InsuranceQueueStatus,
  PatientStatus,
  TaskPrescriptionData,
} from './api'
import { HourRange } from './calendar'
import { DoseSpotPrescription, DoseSpotPrescriptionLogEntry } from './doseSpot'
import { EMRTaskTypeMap, NonVisitEventTitleForTaskType } from './emrTaskTypes'
import { ABF_CODE_TYPE, PrimaryInsurancePlanType, ServiceLineValidator } from './encounters'
import { Appointment, Consent, Patient, Problem } from './models'
import { Employee } from './models/employee'
import { ReferralPartner } from './partners'
import { PatientFile } from './patientFiles'

export * from './account'
export * from './achievements'
export * from './actionItems'
export * from './acuity'
export * from './api'
export * from './appointments'
export * from './bigquery'
export * from './billing'
export * from './calendar'
export * from './callOuts'
export * from './carePathways'
export * from './chargebee'
export * from './cocm'
export * from './consents'
export * from './dates'
export * from './dischargeNote'
export * from './dispatch'
export * from './doseSpot'
export * from './employeeUtils'
export * from './emrTaskTypes'
export * from './encounters'
export * from './errors'
export * from './form'
export * from './icd10Codes'
export * from './insurance'
export * from './invoices'
export * from './mentalHealthEvaluation'
export * from './messageQueue'
export * from './models'
export * from './models/cocm'
export * from './models/employee'
export * from './models/emrNotifications'
export * from './models/notes'
export * from './models/ophelink'
export * from './models/patient'
export * from './models/tasks'
export * from './ocp'
export * from './partners'
export * from './patient'
export * from './patientFiles'
export * from './patientOperation'
export * from './patientUtils'
export * from './payerConfigs'
export * from './paymentPlans'
export * from './referrals'
export * from './releaseOfInformation'
export * from './remoteConfig'
export * from './rxBenefits'
export * from './segment'
export * from './shipfusion'
export * from './states'
export * from './supportRequests'
export * from './tasks'
export * as TestData from './testData'
export * from './thankYouNote'
export * from './truepill'
export * from './twilight/icons'
export * from './utils'
export * from './verifiable'
export * from './workflows'
export * from './zoom'
export type TemporaryAccess = {
  patientId: string
  createdAt: string
}

export type EmployeeAvailability = {
  /**
   * Eg: '2020-02-04'
   */
  date: string
  slots: {
    /**
     * Employee calendar IDs
     */
    calendars: number[]
    /**
     * Eg: '2020-02-04T13:00:00-0800'
     */
    time: string
  }[]
}

export type VisitIdAndLength = {
  id: number
  length: number
  overbooked?: boolean
  staggered?: boolean
  reserve?: boolean
  inactive?: boolean
}

export type AppointmentType = {
  id: number
  name: string
  length: number
}

export type AppointmentData = {
  appointmentType: string
  calendar: string
  clientDate: string
  clientTime: string
}

export type AppointmentWithNoteDetails = Appointment & {
  noteDetails: {
    lockedAt?: string
  }
}

export type InPersonExamRequirementStatus = {
  PA?: 'Non-Emergent' | 'Emergent'
}

export type PatientReferralStatus = {
  referralPartnerId: ReferralPartner['oid']
}

export type WinbackStatus = {
  lastClaimedAt: string
  lastClaimedBy: Employee['oid']
}

export type Statuses = {
  levelOfCare?: LevelOfCareStatus
  patient: PatientStatus
  insuranceAllowList?: boolean
  inPersonExamRequirement?: InPersonExamRequirementStatus
  referral?: PatientReferralStatus
  winback?: WinbackStatus
}

type CalendarEventForRenderBase = {
  // The rem down the screen to draw this event
  top: number

  // The number of indentations to give this event when rendering
  indent: number

  // The start datetime of the event in ISO
  start: string

  // The number of minutes of this event
  duration: number
}

export type UnusedTimeEventForRender = {
  type: 'unused'
} & CalendarEventForRenderBase

export type AppointmentEventForRender = {
  type: 'appointment'
  event: AppointmentWithNoteDetails
} & CalendarEventForRenderBase

export type BlockEventForRender = {
  type: 'block'
  event: AcuityBlock
} & CalendarEventForRenderBase

export type SlotEvent = {
  selected: boolean
  onSelect: () => void
  duration: number
  appointmentType: string
} & AcuityTimeSlot
export type SlotEventForRender = {
  type: 'slot'
  event: SlotEvent
} & CalendarEventForRenderBase

export type OOOEventForRender = {
  type: 'ooo'
} & CalendarEventForRenderBase

export type CalendarEventForRender =
  | AppointmentEventForRender
  | BlockEventForRender
  | SlotEventForRender
  | OOOEventForRender
  | UnusedTimeEventForRender

export type CalendarColumnHeader = {
  id: string
  date: string
  name: string
  day: string
}

export type CalendarColumnData = {
  id: string
  date: string
  visits: AppointmentWithNoteDetails[]
  blocks: AcuityBlock[]
  workingHours?: HourRange[]
}

export const pronouns = ['he/him/his', 'she/her/hers', 'they/them/theirs', 'other'] as const

export type Pronouns = (typeof pronouns)[number]

export const sexes = ['male', 'female', 'intersex', 'other'] as const
export type Sex = (typeof sexes)[number]

export const genders = [
  'male',
  'female',
  'trans man',
  'trans woman',
  'non-binary',
  'other',
] as const
export type Gender = (typeof genders)[number]

export type PersonalData = {
  firstName: string
  lastName: string
  preferredName?: string
  email: string
  phone: string
  birthday?: string
  sex?: Sex
  startDate?: string
  querySlugs?: string[]
  gender?: Gender
  pronouns?: Pronouns
}

export type NameAndId = {
  name: string
  id: string
}

export type OCMRelationships = {
  nurseCareManager: NameAndId
  primaryClinician: NameAndId
}

export type PreferredPharmacy = {
  name: string
  address: string
  city: string
  state: string
  zip: string
  phone: string
  // The pharmacy ID we get from dosespot. This value is only used before a patient has a dosespot account
  pharmacyId?: number
}

export type PatientList = {
  userId: string
  insuranceData: Record<string, Record<string, string>>
  nurseCareManager: NameAndId
  primaryClinician: NameAndId
  personalData: {
    firstName: string
    lastName: string
  }
  statuses: Statuses
}

type DispatchObject = {
  type: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: Record<string, any> | string | number
}

export type Dispatch = (value: DispatchObject) => void

export type Alert = {
  message: string
  type: 'success' | 'error' | undefined
}

export type Payer = {
  name: string
  payerId: string
}

// this type refers to the problem addressed within a visit note
export type ProblemAddressed = {
  oid?: string
  code: string
  full_description: string
  progression: string
  chronicity: string
  treating?: boolean
  status: 'addressed' | 'inactive'
}
/**
 * This type refers to a problem decorated by this function:
 * LINK: server/functions/database/problemsList/index.ts#problem-decorator
 */
export type DecoratedProblem = Problem & {
  full_description?: string
}
export enum ActiveStatus {
  Yes = 'yes',
  No = 'no',
}

export enum ActiveStatusVerificationMethod {
  Calling = 'calling',
  ExternalSoftware = 'external software',
}
export type ManualInsuranceData = {
  created_at?: string
  first_name: string
  gender: string
  last_name: string
  birthday?: string
  patient_relationship_to_subscriber_code: string
  isActive?: boolean
  activeVerificationMethod?:
    | ActiveStatusVerificationMethod.Calling
    | ActiveStatusVerificationMethod.ExternalSoftware
    | undefined
  insurance_card: {
    group_number: string
    member_id: string
    payer_id: string
    plan_name: string
    plan_type?: keyof typeof PrimaryInsurancePlanType
    rx_bin: string
    rx_pcn: string
    payer_name: string
  }
}

export type InsuranceQueue = 'enrollment' | 'payors' | 'flagged'
export type InsuranceType = 'primary' | 'other'
export type InsuranceReviewData = {
  timestamp: string | null
  status: InsuranceQueueStatus
  queue?: InsuranceQueue | null
  queueMessage?: string
  /**
   * This field is not set anywhere in our code, but it is used by customer.io:
   * when we go live with a new insurance plan and add people to the queue by script, we set this as 'true' for tracking
   */
  addedManually?: boolean
}

export const INTENDED_PAYMENT_METHOD = ['Cash', 'Insurance', 'Not enrolling'] as const
export type IntendedPaymentMethod = (typeof INTENDED_PAYMENT_METHOD)[number]

export type FSInsuranceData = {
  insuranceQueueData?: InsuranceReviewData | null
  allowListStatusChanged?: string
  freeformData?: string
  primaryInsuranceId?: string
  secondaryInsuranceId?: string
  hasInsurance?: boolean | null
  hasSecondaryInsurance?: boolean
  intendedPaymentMethod?: IntendedPaymentMethod
}

export type InsuranceReviewQueueItem = {
  insuranceQueueData: InsuranceReviewData
  id: string
  userId: string
  state: string | undefined
  birthday: string | undefined
  firstName: string
  lastName: string
  insurance: InsuranceData | undefined | null
  secondaryInsurance?: InsuranceData | undefined | null
  secondaryManualInsuranceData?: ManualInsuranceData | null | undefined
  eligibleInsuranceData: EligibleInsuranceData | null | undefined
  secondaryEligibleInsuranceData: EligibleInsuranceData | null | undefined
  manualInsuranceData: ManualInsuranceData | null | undefined
  hasInsurance: boolean
  hasSecondaryInsurance: boolean
  consents: Consent[]
  statuses: Statuses
  createdAt: string | undefined
  files: {
    primaryInsuranceFront: PatientFile | null
    primaryInsuranceBack: PatientFile | null
    secondaryInsuranceFront: PatientFile | null
    secondaryInsuranceBack: PatientFile | null
  }
  onInsuranceAllowList: boolean
  upcomingIntake: Appointment | null | undefined
  freeformData: string | undefined
}

export type BaseHeaderButtonConfig = {
  link: string
  pageName: string
}

export type EligibleInsuranceError = {
  reject_reason_description: string
  details: string
  follow_up_action_description: string
}

export type ShipmentParcel = {
  created_at: string
  height: number
  id: string
  length: number
  mode: string
  object: string
  updated_at: string
  weight: number
  width: number
}

export type ShipmentReturnAddress = {
  city: string
  company: string
  country: string
  created_at: string
  id: string
  mode: string
  object: string
  phone: string
  state: string
  street1: string
  street2: string
  updated_at: string
  zip: string
}

export type ShipmentToAddress = {
  city: string
  country: string
  created_at: string
  id: string
  mode: string
  name: string
  object: string
  residential: boolean
  state: string
  street1: string
  street2: string
  updated_at: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  verifications: Record<string, any>
  zip: string
}

export type CreateLabShipment = {
  patientId: string
  name: string
  provider: string
  forceSignature: boolean
  email: string
  rationale: string
  shippingData: AddressData | null
}

export type CreateLabShipmentShipfusion = {
  patientId: string
  name: string
  provider: string
  forceSignature: boolean
  rationale: string
  orderQuantity: number
}

export enum ChargebeeInvoiceStatus {
  Paid = 'paid',
  NotPaid = 'not_paid',
  Due = 'payment_due',
  Void = 'voided',
}

export enum InsuranceChecks {
  Financial = 'Financial Agreemnt',
  Insurance = 'Insurance Verified',
  Birthday = 'Birthday',
  Photos = 'Insurance Photos',
}

export enum EncounterStatuses {
  Unsubmitted = 'unsubmitted',
  NotBillable = 'not_billable',
  Submitted = 'submitted',
  NotOnAllowList = 'allow_list_false',
  Flagged = 'flagged',
  AutoSubmitting = 'auto_submitting',
}

export type Diagnosis = { code: string; name: string; code_type: typeof ABF_CODE_TYPE }

export type PatientVisibility = {
  visible: boolean
  shouldRender: boolean
  temporaryAllowed: boolean
}

export type DecoratedAppointment = {
  startTime: string
  endTime: string
  startTimeUTC: string
  endTimeUTC: string
  location: string
  description: string
  title: string
  ctz: string
  datetime: string
}

export type PatientProps = {
  firstName: string
  lastName: string
  phone: string
  email: string
  datetime?: string
}

export type SkipConsultationSidebarSteps =
  | 'email-collection'
  | 'candidate'
  | 'next-steps'
  | 'how-much-our-program-costs'

export type CalendarSlot = {
  time: string
  calendars: number[]
}

export type FormAnswer = {
  answer: string | Record<string, string> | string[]
  next: string
  image?: string
}

export type FormInput = {
  title: string
  name: string
  placeholder?: string
  defaultValue?: string | number
  validation?: Record<string, Record<string, RegExp | string | boolean>>
  type: 'short_input' | 'long_input' | 'number_input'
}

export type SelectInput = {
  key: string
  value: string
}

export type FormRequestAnswer = {
  key: string
  value: string | number | string[] | Record<string, string>
  description?: string
}

export type FormData = {
  formType?: FormType
  metadata: FormMetadata
  submission: FormSubmission & IntakeJotformSubmission
}

export type FormMetadata = {
  createdAt?: string
  patientId?: string
  source?: string
  updatedAt?: string
}

export type IntakeJotformSubmission = {
  usingOpioids?: string | undefined
  usingOpioidsForHowLong?: string | undefined
  preferredRouteOfAdministration?: string | undefined
  usingHowFrequently?: string | undefined
  spendingPerDay?: string | undefined
  substancesUsedInPastMonth?: string | undefined
  treatedForOUDBefore?: string | undefined
  typeOfTreatmentInPast?: string | undefined
  lastTimeInTreatment?: string | undefined
  takenMedicationToTreatOUD?: string | undefined
  interestedInPrescribedBup?: string | undefined
  drugOverdoseInPast?: string | undefined
  quantityOfDrugOverdoses?: string | undefined
  lastDrugOverdose?: string | undefined
  accessToNarcan?: string | undefined
  mentalHealthState?: string | undefined
  medicalDiagnoses?: string | undefined
  beenHospitalizedInPsychiatricUnit?: string | undefined
  howLongSincePsychiatricHospitalization?: string | undefined
  medicalConditionsList?: string | string[] | undefined
  currentMedicationsList?: string | string[] | undefined
  medicationAllergiesList?: string | string[] | undefined
  recentHospitalizationsList?: string | string[] | undefined
  emergencyContact?: string | string[] | undefined
  dateOfBirth?: string | string[] | undefined
  gender?: string | undefined
  sex?: string | undefined
  pronouns?: string | undefined
  ethnicity?: string | undefined
  preferredPharmacy?: string | undefined
  idSubmission?: string | undefined
  name?: string | undefined
  email?: string | undefined
  address?: string | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  phoneNumber?: string | undefined
  currentCity?: string | undefined
  currentState?: string | undefined
}

export type IntakeFormSubmission = {
  areyou1?: string | undefined
  howlong?: string | undefined
  whatis67?: string | undefined
  howfrequently?: string | undefined
  howmuch62?: string | undefined
  whichof?: string | undefined
  haveyou70?: string | undefined
  whatkind?: string | undefined
  whenwas79?: string | undefined
  haveyou?: string | undefined
  areyou10?: string | undefined
  haveyou51?: string | undefined
  howmany53?: string | undefined
  whenwas52?: string | undefined
  doyou?: string | undefined
  yoursafety?: string | undefined
  haveyou72?: string | undefined
  haveyou73?: string | undefined
  howlong80?: string | undefined
  pleaselist?: string | string[] | undefined
  pleaselist55?: string | string[] | undefined
  anymedication?: string | string[] | undefined
  anyrecent57?: string | string[] | undefined
  emergencycontact?: string | string[] | undefined
  pleaseenter?: string | string[] | undefined
  whatsyour38?: string | undefined
  gender?: string | undefined
  pronouns?: string | undefined
  whatis?: string | undefined
  preferredpharmacy92?: string | undefined
  pleasetake?: string | undefined
  name?: string | undefined
  email?: string | undefined
  address?: string | undefined
  city?: string | undefined
  zip?: string | undefined
  phonenumber?: string | undefined
  pleaseenter94?: string | undefined
  pleaseenter98?: string | undefined
  state?: string | undefined
}

export const patientStatuses = [
  'new',
  'lead',
  'candidate',
  'ineligible',
  'not interested',
  'in treatment',
  'not responding',
  'discharged',
  'paused',
  'needs review',
] as const

export const levelOfCareStatuses = ['weekly', 'biweekly', 'monthly', 'n/a'] as const

export type LevelOfCareStatus = (typeof levelOfCareStatuses)[number]

export type Address = {
  name: string
  address: string
  city: string
  state: string
  zip: string
}

export type Session = {
  ipAddress?: string
  userAgent?: string
  anonymousId?: string
  // Deprecated as of 7/24/23
  gaClientId?: string
}

export type CandidEncounterField =
  | 'allowed_amount_cents'
  | 'appointment_type'
  | 'billing_provider_first_name'
  | 'billing_provider_last_name'
  | 'billing_provider_npi'
  | 'billing_provider_organization_name'
  | 'billing_provider_tax_id'
  | 'charge_amount_cents'
  | 'check_dates'
  | 'created_datetime'
  | 'date_of_service'
  | 'diagnosis_codes'
  | 'do_not_bill'
  | 'encounter_external_id'
  | 'encounter_id'
  | 'paid_amount_cents'
  | 'patient_external_id'
  | 'patient_responsibility_cents'
  | 'payer_cpid'
  | 'payer_id'
  | 'payer_name'
  | 'place_of_service_code'
  | 'procedure_codes'
  | 'rendering_provider_first_name'
  | 'rendering_provider_last_name'
  | 'rendering_provider_npi'
  | 'rendering_provider_organization_name'
  | 'service_facility_city'
  | 'service_facility_organization_name'
  | 'service_facility_state'
  | 'service_facility_zip_code'
  | 'state_transition_datetime'
  | 'status'

export type CandidEncounter = Record<CandidEncounterField, string>

export type CandidEncounterV3Field =
  | 'encounter_id'
  | 'encounter_external_id'
  | 'patient_external_id'
  | 'claim_id'
  | 'status'
  | 'created_datetime'
  | 'state_transition_datetime'
  | 'do_not_bill'
  | 'payer_name'
  | 'payer_id'
  | 'insurance_type'
  | 'date_of_service'
  | 'appointment_type'
  | 'place_of_service_code'
  | 'charge_amount_cents'
  | 'allowed_amount_cents'
  | 'paid_amount_cents'
  | 'patient_responsibility_cents'
  | 'copay_cents'
  | 'coinsurance_cents'
  | 'deductible_cents'
  | 'patient_payments_cents'
  | 'patient_balance_cents'
  | 'diagnosis_codes'
  | 'procedure_codes'
  | 'claim_adjustment_reason_codes'
  | 'remittance_advice_remark_codes'
  | 'denial_reasons'
  | 'latest_check_date'
  | 'latest_check_number'
  | 'billing_provider_first_name'
  | 'billing_provider_last_name'
  | 'billing_provider_organization_name'
  | 'billing_provider_tax_id'
  | 'billing_provider_npi'
  | 'rendering_provider_first_name'
  | 'rendering_provider_last_name'
  | 'rendering_provider_organization_name'
  | 'rendering_provider_tax_id'
  | 'rendering_provider_npi'
  | 'service_facility_organization_name'
  | 'service_facility_city'
  | 'service_facility_state'
  | 'service_facility_zip_code'
  | 'adjudicated_network_status'
  | 'organization_id'

export type CandidEncounterV3 = Record<CandidEncounterV3Field, string>

export type TaskDetails = {
  prescription: TaskPrescriptionData
  userId: string
  pharmacy?: {
    name?: string
    address?: string
    city?: string
    state?: string
    zip?: string
  }
  ocmRelationships?: OCMRelationships
  homeData?: AddressData
  personalData?: PersonalData
}

export type Task = {
  closedBy?: string
  details: TaskDetails
  requestedById: string
  requestedByName: string
  status?: string
  taskTimezone?: string
  timestampCreated: string
  timestampMarkedAsClosed?: string
  title: string
  type: string
}

export type FrontendTask = Task & { id: string }

export type PrescriptionSummary = {
  prescription: DoseSpotPrescription
  patient: Patient
  employee: Employee<'clinician'>
  logs: DoseSpotPrescriptionLogEntry[]
}

export type DrugScreenOptions =
  | 'drug_screen_alcohol_result'
  | 'drug_screen_amphetamines_result'
  | 'drug_screen_barbiturates_result'
  | 'drug_screen_benzodiazepines_result'
  | 'drug_screen_buprenorphine_result'
  | 'drug_screen_cocaine_result'
  | 'drug_screen_fentanyl_result'
  | 'drug_screen_marijuana_result'
  | 'drug_screen_methadone_result'
  | 'drug_screen_methamphetamine_result'
  | 'drug_screen_methylenedioxymethamphetamine_result'
  | 'drug_screen_opiates_result'
  | 'drug_screen_oxycodone_result'
  | 'drug_screen_phencyclidine_result'
  | 'drug_screen_synthetic_cannabinoid_result'
  | 'drug_screen_tramadol_result'

export type DrugScreenResults = {
  [key in DrugScreenOptions]: boolean
}

export const drugScreenInfo = {
  drug_screen_buprenorphine_result: {
    abbreviation: 'BUP',
    name: 'Buprenorphine',
  },
  drug_screen_alcohol_result: { abbreviation: 'ETG', name: 'Alcohol' },
  drug_screen_amphetamines_result: {
    abbreviation: 'AMP',
    name: 'Amphetamines',
  },
  drug_screen_barbiturates_result: {
    abbreviation: 'BAR',
    name: 'Barbiturates',
  },
  drug_screen_benzodiazepines_result: {
    abbreviation: 'BZO',
    name: 'Benzodiazepines',
  },
  drug_screen_cocaine_result: { abbreviation: 'COC', name: 'Cocaine' },
  drug_screen_fentanyl_result: { abbreviation: 'FEN', name: 'Fentanyl' },
  drug_screen_marijuana_result: { abbreviation: 'THC', name: 'Marijuana' },
  drug_screen_methadone_result: { abbreviation: 'MTD', name: 'Methadone' },
  drug_screen_methamphetamine_result: {
    abbreviation: 'MAMP',
    name: 'Methamphetamine',
  },
  drug_screen_methylenedioxymethamphetamine_result: {
    abbreviation: 'MDMA',
    name: 'Methylenedioxymethamphetamine',
  },
  drug_screen_opiates_result: { abbreviation: 'OPI', name: 'Opiates' },
  drug_screen_oxycodone_result: { abbreviation: 'OXY', name: 'Oxycodone' },
  drug_screen_phencyclidine_result: {
    abbreviation: 'PCP',
    name: 'Phencyclidine',
  },
  drug_screen_synthetic_cannabinoid_result: {
    abbreviation: 'K2',
    name: 'Synthetic Cannabinoid',
  },
  drug_screen_tramadol_result: { abbreviation: 'TRAM', name: 'Tramadol' },
} as const

export type AnonymousAppointmentInfo = {
  clinician: string
  confirmed: boolean
  datetime: string
  type: string
}

export type SubscriptionData = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chargebeeCustomer?: Record<string, any>
  metadata: {
    paymentStartDate?: string
    // _Should_ be a string, but the addition of startSubscriptionIfExitAllowList began treating this as a number
    paymentDelay?: string | number
    updatedAt: string
    chargebeeToken?: string
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ChargebeeSubscriptionData = Record<string, any>

export type ServiceLine = z.infer<typeof ServiceLineValidator>

export enum BillingType {
  Time = 'Time',
  MDM = 'MDM',
}

export type CaseReviewNoteContent = {
  summary?: string
  assessmentAndRecommendations?: string
  preWorkNotes?: string
  preWorkMinutes?: string
  psychConsultantId?: string
  // Legacy case review note fields
  assessment?: string
  recommendations?: string
}

export const qualifyingActivities = [
  'General outreach',
  'Measurement-based care assessment',
  'Patient medical communication',
  'Pharmacy engagement',
  'Prescription queueing',
  'Referral and resource navigation',
  'Registry activity',
  'Treatment plan update',
] as const

// cc time tracking activies have been replaced by issue tracker 3/06/23, but are still needed in `CocmTimeTrackingActivities` to display past activities
export const ccTimeTrackingActivities = [
  'Clinician transfer',
  'Discontinuation of care',
  'Patient medical communication',
  'Patient outreach',
  'Pharmacy engagement',
  'Prescription issue',
  'Prior authorization',
  'Referral and resource navigation',
  'ROI',
] as const

const EMRTaskTypeLabels = EMRTaskTypeMap.map(taskType => taskType.label)
export type CocmTimeTrackingActivities =
  | (typeof qualifyingActivities)[number]
  | (typeof ccTimeTrackingActivities)[number]
  | (typeof EMRTaskTypeLabels)[number]
  | 'Case review'
  | 'Wellness visit'
  | 'UDS visit'
  | 'Check-in call'
  | 'Unknown'

export type UserSession = {
  anonymousId?: string
  gaClientId?: string
  ipAddress?: string
  userAgent?: string
}

export const NonVisitEventTitles = [
  'Miscellaneous Event',
  'Pharmacy Event',
  'Medical/Psych Event',
  'Social Event',
  'Care Coordination Event',
  'CoCM treatment guidance',
  'CoCM discharge',
] as const

export type NonVisitEventTitle =
  | (typeof NonVisitEventTitles)[number]
  | NonVisitEventTitleForTaskType
