import { User } from 'firebase/auth'
import {
  AccountData,
  ActionItemAssignmentMethod,
  ActionItemStatus,
  ActionItemType,
  AddressData,
  AlternateProviderStatus,
  AppointmentTypeString,
  BillingType,
  CandidEncounter,
  CandidEncounterV3,
  CaseReviewNoteContent,
  CocmTimeTrackingActivities,
  Consents,
  Diagnosis,
  DrugScreenResults,
  EligibleInsuranceData,
  Employee,
  EncounterAppointmentType,
  EncounterStatuses,
  FSInsuranceData,
  FinancialConsentVersion,
  HLOCCategory,
  HLOCSubcategory,
  ISOString,
  InsuranceData,
  LevelOfCareStatus,
  MMDDYYYY,
  ManualInsuranceData,
  NameAndId,
  NonVisitEventTitle,
  OtherString,
  PatientAttributes,
  PatientEmergencyContact,
  PatientFile,
  PatientSelfDeterminesToStopReason,
  PatientStatus,
  PatientTaskType,
  PatientWorkflows,
  PaymentPlanFrequency,
  PersonalData,
  PreferredPharmacy,
  PrescribingClinicianPreference,
  ProblemAddressed,
  PromptResponseContextKey,
  ReferralOption,
  ReferralPartner,
  ReferralSource,
  ReferralTracking,
  ReleaseOfInformationChangeRequest,
  ReleaseOfInformationStatus,
  RequireAtLeastOne,
  SegmentAdditionalContext,
  SegmentContext,
  SegmentMethod,
  SegmentTraits,
  ServiceLine,
  ShipmentItems,
  ShipmentParcel,
  ShipmentReturnAddress,
  ShipmentStatus,
  ShipmentToAddress,
  ShipmentTrackingInfo,
  ShippingPartner,
  StateName,
  Statuses,
  StripeInvoice,
  SubscriptionData,
  SupportRequestCategory,
  SupportRequestSeverity,
  TPO_CONSENT_VERSIONS,
  TREATMENT_CONSENT_VERSIONS,
  TaskPrescriptionData,
  TruepillErrorResponse,
  TruepillOrderErrorWebhook,
  TruepillOrderWebhook,
  TruepillRequest,
  TruepillResponse,
  TruepillShipmentWebhook,
  UserSession,
  VerifiableProviderEnrollment,
  YYYYMMDD,
  YesNo,
} from '.'
import { BadgeType, MilestoneType } from './achievements'
import {
  BillingProvider,
  EncounterPatientData,
  EncounterSubscriber,
  RenderingProvider,
  ServiceFacility,
} from './encounters'
import { MentalHealthEvaluationDiagnosis, ReasonForNoEvaluation } from './mentalHealthEvaluation'
import { PrescriptionBenefitsData } from './rxBenefits'
import { WelcomeCallPromptResponseContextKey } from './workflows/contextKeys/welcomeCall'

/**
 * A "FooModel" represents the model that _goes into_ the DB.
 * A "Foo" represents what comes _out of_ the DB (has "oid" mixed-in automatically).
 * We chose "oid" instead of "id" b/c too many pre-existing models had polluted "id" already.
 */
type Oid = {
  oid: string
}

type Timestamps = {
  createdAt: number
  updatedAt: number
}

export type DatabaseMetadata = Oid & { timestamps: Timestamps }

// Using a record for typescript to require any new database metadata fields to be added here
const _databaseMetadataFields: Record<keyof DatabaseMetadata, keyof DatabaseMetadata> = {
  oid: 'oid',
  timestamps: 'timestamps',
}

export const databaseMetadataFields = Object.values(_databaseMetadataFields)

export type ProviderType =
  | 'Nurse Practitioner'
  | 'Physician Assistant'
  | 'Registered Nurse'
  | 'Medical Doctor'

export type SegmentEventModel = {
  method: SegmentMethod
  context: SegmentContext
  timestamp: number
  traits?: SegmentTraits
  additionalContext?: SegmentAdditionalContext
  /**
   * This field is added in the subscriber to track the time the event was processed (ie send to Rudderstack).
   * If the event fails to process, this field will be null.
   */
  processedAt?: ISOString | null
  status?: 'success' | 'failure'
}

export type SegmentEvent = SegmentEventModel & DatabaseMetadata

export type VerifiablePayerPlanModel = {
  payerPlanId: string
  name: string | null
  displayName: string | null
  state: string | null
  eligibleIds: string[] | null
  enrollments: VerifiableProviderEnrollment[] | null
}

export type VerifiablePayerPlan = VerifiablePayerPlanModel & DatabaseMetadata

export type ShipmentModel = {
  carrier: string
  deleted?: boolean
  email: string
  label_url: string
  parcel: ShipmentParcel
  provider: string
  public_tracking_url: string
  rate: string
  return_address: ShipmentReturnAddress
  shipped: boolean
  id: string
  sid: string
  timestamp: string
  to_address: ShipmentToAddress
  tracking_code: string
  userId: string
  rationale: string
  closedAt?: string
}

export type Shipment = ShipmentModel & DatabaseMetadata

export type ICD10CodeModel = {
  code: string
  full_description: string
  long_description: string
  short_description: string
  status: string
  validity: 'C' | 'I'
  // Contains all CoCm eligible codes and some other recommended behavioral health codes
  isBehavioralHealthCode: boolean
  // This is currently unused, but will be used in the future to show codes that we recommend
  isCommonDiagnosisCode: false
  // These indicate codes that we use to determine which forms to show to the patient when they are added to the cocm registry
  isPhq9RelatedCode: boolean
  isGad7RelatedCode: boolean

  isCocmEligibleCode: boolean
  createdAt: string
}

export type ICD10Code = ICD10CodeModel & DatabaseMetadata

export type ChangeToEligibleMapping = {
  changeSubmissionPayerId: string
  eligiblePayerId: string
  name: string
  // Not present often, but this is used in cases where one eligible payer maps to multiple change submission payers
  eligibleName?: string
  // Default mapping for when there are multiple changeSubmissionPayers for a single eligiblePayerId
  isDefault?: boolean
}

export type ByEntity = 'patient' | 'ophelia_employee' | 'system'

export type AppointmentConfirmation = {
  confirmed: boolean
  confirmedAt: string
  confirmedBy: ByEntity
}

export type VirtualMeetingDetails = {
  id: number
  uuid: string
  topic: string
  password: string
  hostEmail: string
  clinicianJoinUrl: string
  patientJoinUrl: string
  // patientRedirectUrl is an Ophelia-managed link that redirects to the patientJoinUrl (which can be long and ugly)
  patientRedirectUrl: string
  generalJoinUrl: string
  createdAt: string
  // If we use another video chat platform one day, we can extend this type to model both zoom and the new platform
  platform: 'zoom'
}

export const reasonsForVisitWithPc = [
  'Quarterly visit',
  'OUD instability',
  'Behavioral health instability',
  'No CCM availability',
  'Other',
] as const

export type ReasonForVisitWithPc = (typeof reasonsForVisitWithPc)[number]

export const reasonsForVisitWithCcm = ['No PC availability', 'Other'] as const

export type ReasonForVisitWithCcm = (typeof reasonsForVisitWithCcm)[number]

export type AppointmentModel = {
  // The Acuity appointmentId
  id: number
  appointmentTypeID: number
  calendar: string
  calendarID: number
  calendarTimezone: string
  canceled: boolean
  canceledAt?: string
  canceledBy?: ByEntity
  canceledById?: string
  category: string
  clinicianTime?: number
  patientTime?: number
  smokingCessationServiceTime?: number
  createdBy?: ByEntity
  createdById?: string
  confirmed: boolean
  confirmedAt?: string
  confirmedBy?: ByEntity
  confirmationHistory?: AppointmentConfirmation[]
  confirmationPage: string
  date: string
  dateCreated: string
  datetime: ISOString
  datetimeCreated: string
  duration: string
  email: string
  employeeId: string
  endTime: string
  firstName: string
  lastName: string
  /*
   * TODO(haven): Remove this comment after migration
   * During the migration to generating Zoom meetings in our own system, the `location` field may be overwritten
   */
  location: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meetingDetails?: Record<string, any>
  // Generated by us to allow fetching the virtualAppointmentDetails
  meetingPasscode?: string
  // The initial date time of the appointment (in-case it has been re-scheduled)
  initialDatetime?: string
  phone: string
  scheduledBy?: string
  time: string
  timezone: string
  type: AppointmentTypeString
  userId: Patient['oid']
  /**
   * From Acuity Docs:
   * Canceled appointment responses include a noShow field which is true if the admin has marked
   * the appointment as a no-show, and false if the appointment is simply canceled.
   */
  noShow?: boolean
  noShowBillProcessed?: boolean
  noShowBillInvoiceId?: string
  stripeNoShowInvoiceId?: string
  // Everything in metadata is added by us and not part of the Acuity API response for an appointment.
  metadata?: AppointmentMetadata
}

export type AppointmentMetadata = {
  isCallMeNow?: boolean
  highRiskPatient?: boolean
  highRiskPatientResponses?: WelcomeCallPromptResponseContextKey[]
  virtualAppointmentDetails?: VirtualMeetingDetails
  withCustomPhone?: { phone: string; ext: string }
  referralPartnerId?: string
  workflowSessionId?: string
  isPrioritySlot?: boolean
  /*
   * For appointments that CCMs schedule with PCs where the patient is eligible to meet with a CCM instead,
   * we ask CCMs why they scheduled the appointment with the PC instead of themselves
   */
  reasonForVisitWithPc?: ReasonForVisitWithPc
  reasonForVisitWithCcm?: ReasonForVisitWithCcm
  reasonForVisitWithPcOther?: string
  reasonForVisitWithCcmOther?: string
  reasonForReserveVisit?: string
  enrollmentCoordinator?: NameAndId
  // Indicates that the patient should prepare to complete a UDS during the appointment
  shouldSendUdsReminder?: boolean
  /**
   * This is indicated by CCs and/or PCs when they schedule an appointment with a patient
   * that ideally can be pulled forward to an earlier date/time if availability opens up.
   */
  isStandby?: boolean
  // Added 3/15/24 - Experiment to allow patients to skip onboarding and book instant welcome call
  skippedOnboarding?: boolean
  // Indicates that the patient was eligible to be held but requested to be scheduled at a specific time
  patientEligibleForHoldRequestedSpecificAppointmentTime?: boolean
  // Indicates that the appointment was scheduled through a calendar hold by including the calendar hold id
  calendarHoldId?: string
  isAutoScheduledFromCalendarHold?: boolean
  isSelfScheduledFromCalendarHold?: boolean
  // The datetime that this visit will expire if tasks are not completed by then
  patientTaskExpirationDatetime?: string
  // If this visit expired due to tasks, which ones were still open when cancelled
  expiredTasks?: PatientTaskType[]
  // Exists if appointment was manually created by an employee during a drop-in clinic
  dropInClinicId?: string
}

export type Appointment = AppointmentModel & DatabaseMetadata

export type PaymentModel = {
  amount: number
  amount_unused?: number
  base_currency_code: string
  currency_code: string
  customer_id: string
  // unix timestamp
  date: number
  deleted: boolean
  exchange_rate: number
  fraud_reason?: string
  gateway: string
  gateway_account_id: string
  id: string
  id_at_gateway: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  linked_credit_notes?: Record<string, any>[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  linked_invoices?: Record<string, any>[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  linked_refunds?: Record<string, any>[]
  masked_card_number: string
  object: string
  payment_method: string
  payment_method_details?: string
  payment_source_id: string
  refunded_txn_id?: string
  resource_version: number
  status: string
  subscription_id: string
  type: string
  // unix timestamp
  updated_at: number
}

// TODO: this list is not exahustive, and this collection's code is still broadly untyped
export type ProblemListProblem = ProblemAddressed & {
  createdAt?: string
  updatedAt?: string
  deactivatedAt?: string
}

export type Payment = PaymentModel & DatabaseMetadata

export const dischargeStatuses: PatientStatus[] = ['paused', 'not responding']

export const dischargeReasons = [
  'clinicial',
  'death',
  'non-compliance',
  'non-responsive',
  'payment',
  'ABP non-compliance',
  'self-determined end of care',
] as const

export type DischargeReason = (typeof dischargeReasons)[number]

export const EMPTY_DISCHARGE_STATE: PatientModel['discharge'] = {
  note: '',
  reason: '',
  date: '',
}

export type MotivationsForTreatment = {
  motivationsDescription: string
}

export type PatientModel = {
  account?: AccountData
  personalData: PersonalData
  attributes?: PatientAttributes
  pharmacy?: PreferredPharmacy
  emergencyContact?: PatientEmergencyContact | null
  shippingData?: AddressData
  homeData?: AddressData
  insuranceData?: FSInsuranceData
  prescriptionBenefits?: PrescriptionBenefitsData
  statuses: Statuses
  workflows?: PatientWorkflows
  userId?: string
  doseSpotId?: number
  truepillId?: string
  consents?: Consents
  primaryClinician?: NameAndId
  nurseCareManager?: NameAndId
  enrollmentCoordinator?: NameAndId
  subscription?: SubscriptionData
  lastSession?: UserSession
  waivedNoShowUsed?: boolean
  isEligibleForDoubleBooking?: boolean
  isEligibleForStaggering?: boolean
  formsToCollect?: {
    /**
     * This is technically the phq8 now, but we're keeping the field name for backwards compatibility
     */
    phq9: boolean
    gad7: boolean
  }
  discharge?: {
    note: string
    reason: DischargeReason | ''
    date: MMDDYYYY | ''
  }
  stripeCustomerId?: string
  autoPayment?: {
    pausedAt: ISOString | null
    pausedUntil: ISOString | null
    pausedReason: string | null
  }
  ccmTransition?: {
    readyForCcmTransition: 'yes' | 'no' | null
    reasonForNoCcmTransition: string | null
    updatedAt: ISOString
    // `reasonForNoCcmTransitionOther` is optional field because it was added after the fact
    reasonForNoCcmTransitionOther?: string
  }
  nextDropInClinic?: {
    clinicId: string
    confirmedAt?: ISOString | null
  } | null
  /*
   * We're running an experiment to hold off on scheduling monthly patients for a few weeks in the
   * hopes of preserving intake capacity. This field is used to track when we can start scheduling
   * monthly patients again and to prevent these patients from being marked as not responding
   */
  delaySchedulingUntil?: ISOString | null
  motivationsForTreatment?: MotivationsForTreatment
}

// https://262.ecma-international.org/5.1/#sec-15.9.1.1
const MAX_MS_SINCE_EPOCH = 8640000000000000
export const MAX_ISO_STRING = new Date(MAX_MS_SINCE_EPOCH).toISOString() as ISOString

export type PatientHistoricalChangeProperty = 'status' | 'level-of-care'

export type PatientHistoricalChangeModel<
  T extends PatientHistoricalChangeProperty = PatientHistoricalChangeProperty,
> = {
  changedBy: ByEntity
  changedById?: string | undefined
  changedAt: string
  property: T
  value: T extends 'status' ? PatientStatus : LevelOfCareStatus
}

export type PatientHistoricalChange<
  T extends PatientHistoricalChangeProperty = PatientHistoricalChangeProperty,
> = PatientHistoricalChangeModel<T> & DatabaseMetadata

export type Patient = PatientModel & DatabaseMetadata

// Patient Subcollection
export type Phq9FormResponseModel = {
  data: {
    score: number
    key: PromptResponseContextKey<'phq_9_form'>
  }[]
  compositeScore: number
  workflowSessionId: string
  createdAt: string
  completedAt?: string | null
}

export type Phq9FormResponse = Phq9FormResponseModel & DatabaseMetadata

export type Phq8FormResponseModel = {
  data: {
    score: number
    key: PromptResponseContextKey<'phq_8_form'>
  }[]
  // This is a boolean to indicate whether the responses were from a PHQ-9 workflow or PHQ-8 workflow
  isFromPhq9: boolean
  compositeScore: number
  workflowSessionId: string
  createdAt: string
  completedAt?: string | null
}

export type Phq8FormResponse = Phq8FormResponseModel & DatabaseMetadata

export type Gad7FormResponseModel = {
  data: {
    score: number
    key: PromptResponseContextKey<'gad_7_form'>
  }[]
  compositeScore: number
  workflowSessionId: string
  createdAt: string
  completedAt?: string | null
}

export type Gad7FormResponse = Gad7FormResponseModel & DatabaseMetadata

export type Barc10FormResponseModel = {
  data: {
    score: number
    key: PromptResponseContextKey<'barc_10_form'>
  }[]
  compositeScore: number
  workflowSessionId: string
  createdAt: string
  completedAt?: string | null
}

export type Barc10FormResponse = Barc10FormResponseModel & DatabaseMetadata

export type AsqFormResponseModel = {
  data: {
    score: number
    key: PromptResponseContextKey<'asq_form'>
  }[]
  compositeScore: number
  workflowSessionId: string
  createdAt: string
  completedAt?: string | null
}

export type AsqFormResponse = AsqFormResponseModel & DatabaseMetadata

/**
 * @deprecated - moved to Luna
 */
export type ActionItemModel = {
  type: ActionItemType
  /** User-defined if action item type is Manual, otherwise hardcoded */
  title?: string
  /** User-defined if action item type is Manual, otherwise hardcoded */
  description?: string
  /** ID of employee responsible for completing action item */
  assignee: Employee['oid']
  /** ID of employee that created action item, if applicable */
  createdBy?: Employee['oid']
  /** Joined with patient document at request-time */
  createdByName?: string
  status: ActionItemStatus
  createdAt: string
  dueAt: string
  completedAt?: string
  /** ID of employee that manually completed action item, if applicable */
  completedBy?: string
  updatedAt: string
  assignmentMethod: ActionItemAssignmentMethod
  optional: boolean
  deleted: boolean
  /** ID of employee that manually deleted action item, if applicable */
  deletedBy?: string
  patientId: Patient['oid']
  /** Joined with patient document at request-time */
  patientName?: string
  /** The Acuity Appointment Id */
  visitId?: number
  /** The DoseSpot Prescription Id */
  prescriptionId?: number
  /** The oid of the caseReviewNote document */
  caseReviewNoteId?: string
}

export type ActionItem = ActionItemModel & DatabaseMetadata

export type PrescriptionModel = {
  prescription_id?: number
  medication_days_supply?: string
  medication_name: string
  medication_quantity: string
  medication_strength: string
  patient_id: string
  prescription_queued_by: string
  prescription_sent_by: string
  timestamp: string
  effective_date: string
  type?: 'bridge' | undefined
}

export type Prescription = PrescriptionModel & DatabaseMetadata

export type PrescriptionFavoriteModel = {
  favoriteName: string
  medicationName: string
  doseForm: string
  strength: string
  refills?: number
  quantity?: number
  daysSupply?: number
  directions?: string
  substitutionAllowed?: boolean
  type: 'ophelia' | 'clinician'
  category?: string
  pharmacyNotes?: string
  employeeId?: Employee['oid']
}
export type PrescriptionFavorite = PrescriptionFavoriteModel & DatabaseMetadata

export declare type PrescriptionCancelationReason =
  | 'Pharmacy transfer - stocking issues'
  | 'Pharmacy transfer - Pharmacy/pharmacist pushback'
  | 'Duplicate sent'
  | 'Resend due to change in dose or formulation'
  | 'Insurance issue'
  | 'Missing information'
  | 'Clinician error'
  | 'Other'

export type EncounterModel = {
  clinicianTime?: number
  patientTime?: number
  smokingCessationServiceTime?: number
  billingType?: BillingType
  candid?: CandidEncounter | CandidEncounterV3
  candidId?: string
  status?: EncounterStatuses
  /**
   * @deprecated
   * This field is now unnessicary since autopay status is computed on the fly once an encounter is ready for billing.
   */
  autopay?: boolean
  patientPaidCents?: string
  copay_invoice_due_date?: string
  copay_payment_status?:
    | 'paid'
    | 'posted'
    | 'payment_due'
    | 'not_paid'
    | 'voided'
    | 'pending'
    | OtherString
  chargebee_invoice_id?: string
  appointmentId?: number
  onAllowList?: boolean
  date_of_service: YYYYMMDD
  end_date_of_service?: YYYYMMDD
  patient_authorized_release?: boolean
  benefits_assigned_to_provider?: boolean
  provider_accepts_assignment?: boolean
  billing_provider?: BillingProvider
  rendering_provider?: RenderingProvider | null
  service_facility?: ServiceFacility
  patient: EncounterPatientData
  subscriber_primary?: EncounterSubscriber | null
  subscriber_secondary?: EncounterSubscriber | null
  place_of_service_code?: string
  diagnoses?: Diagnosis[]
  service_lines: ServiceLine[]
  prior_authorization_number?: string
  appointment_type?: EncounterAppointmentType
  reviewerId?: Employee['oid']
  reviewedDate?: string
  stripe_invoice_id?: string
  stripe_invoice_status?: StripeInvoice['status']
  stripe_invoice_amount_in_cents?: number | null
  is_same_day_copay?: boolean
  // YYYY-MM-DD. This will only be present if the encounter was generated as part of a bundle
  bundle_run_date?: string
  // If an encounter is unsubmitted, this field will contain the reason why
  unsubmitted_reason?: string | null
}

export type Encounter = EncounterModel & DatabaseMetadata

export type ZoomWebhookEventType = 'meeting.participant_joined'

// Note: Zoom shares more fields, but I'm just including the ones we may need
export type ZoomParticipant = {
  user_name: string
  email: string
  join_time: ISOString
  // The participant_user_id is the field we're storing on EmployeeModel as `zoomUserId`
  participant_user_id: string
}

// TODO Define object more clearly once we start collecting these events and have some data to go off of
export type ZoomWebhookEventPayloadObject<T extends ZoomWebhookEventType = ZoomWebhookEventType> =
  T extends 'meeting.participant_joined'
    ? {
        // The Zoom meeting id
        id: string
        // Info about the participant who joined
        participant: ZoomParticipant
      }
    : // eslint-disable-next-line @typescript-eslint/no-explicit-any
      any

export type ZoomWebhookEventPayload<T extends ZoomWebhookEventType = ZoomWebhookEventType> = {
  account_id: string
  object: ZoomWebhookEventPayloadObject<T>
}

export type ZoomWebhookEventModel<T extends ZoomWebhookEventType = ZoomWebhookEventType> = {
  payload: ZoomWebhookEventPayload<T>
  event_ts: number
  event: T
}
export type ZoomWebhookEvent<T extends ZoomWebhookEventType = ZoomWebhookEventType> =
  ZoomWebhookEventModel<T> & DatabaseMetadata

export type CustomerioFormEventModel = Record<string, string>
export type CustomerioFormEvent = CustomerioFormEventModel & DatabaseMetadata

export type AcuityWebhookEventModel = { appointmentId: string }
export type AcuityWebhookEvent = AcuityWebhookEventModel & DatabaseMetadata

export type TruepillModel = TruepillRequest & {
  employeeId: Employee['oid']
  rationale: string
  timestamp: string
  response: TruepillResponse | TruepillErrorResponse
}

export type Truepill = TruepillModel & DatabaseMetadata

export type TruepillOrderModel = TruepillOrderWebhook | TruepillOrderErrorWebhook

export type TruepillOrder = TruepillOrderModel & DatabaseMetadata

export type TruepillShipmentModel = TruepillShipmentWebhook

export type TruepillShipment = TruepillShipmentModel & DatabaseMetadata

export type ShipfusionModel = {
  patientId: Patient['oid']
  employeeId: Employee['oid']
  shipmentId: string
  timestamp: string
  status: ShipmentStatus
  items: ShipmentItems
  address: AddressData
  carrier: string
  shippingPartner: ShippingPartner
  trackingInfo: ShipmentTrackingInfo
  rationale: string
}

export type Shipfusion = ShipfusionModel & DatabaseMetadata

// @deprecated - moved to Luna
export type NonVisitEventModel = {
  authorId?: Employee['oid']
  content: string
  createdAt: string
  patientId: Patient['oid']
  title: NonVisitEventTitle
  /**
   * 'titleFreeform' is a newer field and therefore optional, as not all docs will have it.
   */
  titleFreeform?: string
  /**
   * @deprecated
   * 'kustomerConversationId' is a newer field and therefore optional, as not all docs will have it.
   */
  kustomerConversationId?: string
  /**
   * 'taskId' is the id of the emrTask from which this NonVisitEvent was created.
   */
  taskId?: string
  deleted?: boolean
}

export type NonVisitEvent = NonVisitEventModel & DatabaseMetadata

export type DrugScreenModel = {
  administeredAt: string
  authorId: Employee['oid']
  createdAt: string
  patientId: Patient['oid']
  results: DrugScreenResults
  deleted?: boolean
}

export type DrugScreen = DrugScreenModel & DatabaseMetadata

export type DischargeNoteModel = {
  additional_explanation_for_discharge: string
  additional_notes: string
  administrative_non_compliance: boolean
  administrative_non_compliance_aggressive_threatening: boolean
  administrative_non_compliance_invoices: boolean
  administrative_non_compliance_no_show: boolean
  administrative_non_compliance_non_adherence: boolean
  administrative_non_compliance_other: boolean
  alternative_provider_status: AlternateProviderStatus
  alternative_provider_other_explanation: string
  completed_medication_and_not_continuing: boolean
  death: boolean
  discharge_visit_status:
    | 'scheduled_and_attended'
    | 'scheduled_and_not_attended'
    | 'offered_and_declined'
  eligible_to_return: YesNo
  eligible_to_return_additional_details: string
  eligible_to_return_review_within_30_days: boolean
  employeeId: Employee['oid']
  failure_to_pay: boolean
  medical_or_psychiatric_worsening: boolean
  medical_or_psychiatric_worsening_additional_details: string
  medical_or_psychiatric_worsening_clinical_worsening: boolean
  medical_or_psychiatric_worsening_dependent_and_inpatient: boolean
  medical_or_psychiatric_worsening_non_adherence: boolean
  medical_or_psychiatric_worsening_other: boolean
  medication_plan_other: boolean
  medication_plan_explanation: string
  naloxone_prescribed: boolean
  needs_transfer_hloc?: YesNo
  not_responding?: string
  patientId: Patient['oid']
  patient_self_determines_to_stop: boolean
  patient_self_determines_to_stop_reasons: PatientSelfDeterminesToStopReason[]
  patient_self_determines_to_stop_primary_reason?: PatientSelfDeterminesToStopReason
  patient_self_determines_to_stop_discussed_with_clinician: boolean
  prescribing_clinician_preference: PrescribingClinicianPreference
  reason_for_discharge_other: boolean
  recommended_hloc?: HLOCCategory
  recommended_hloc_subcategory?: HLOCSubcategory
  referral_request: string
  referral_request_buprenorphine_clinic: boolean
  no_referral_request_explanation: string
  referral_request_additional_details: string
  referral_request_inpatient_program: boolean
  referral_request_intensive_outpatient_program: boolean
  referral_request_mental_health_psychiatric: boolean
  referral_request_methadone_program: boolean
  referral_request_other: boolean
  referral_request_pcp: boolean
  referral_request_residential_program: boolean
  referral_request_urgent?: YesNo
  relocation: boolean
  thirty_day_prescription_current_maintenance_dose: boolean
  thirty_day_prescription_taper: boolean
  thirty_day_prescription_taper_dose_4: boolean
  thirty_day_prescription_taper_dose_8: boolean
  thirty_day_prescription_taper_dose_9_15: boolean
  thirty_day_prescription_taper_dose_16: boolean
  thirty_day_prescription_taper_dose_24: boolean
  timestamp: string
  transferring_to_outpatient_or_mat: boolean
  transferring_to_outpatient_or_mat_buprenorphine: boolean
  transferring_to_outpatient_or_mat_methadone: boolean
  transferring_to_outpatient_or_mat_xr_naltrexone: boolean
}

export type DischargeNote = DischargeNoteModel & DatabaseMetadata

export type AwaitingConsultationCallModel = {
  appointmentId: Appointment['id']
  userId: Patient['oid']
  email: string
  phone: string
  firstName: string
  lastName: string
  timestamp: string
}

export type AwaitingConsultationCall = AwaitingConsultationCallModel & DatabaseMetadata

export type Referral = {
  patientId?: Patient['oid']
  value: ReferralOption
  source: ReferralSource

  // Who submitted this referral? Null if patient-submitted
  employeeId?: Employee['oid']

  // If this referral comes from a visit, which one
  visitId?: Appointment['id']

  referralPartnerId?: ReferralPartner['oid']

  // Additional information related to this value
  additional?: ReferralTracking

  personalizedReferral?:
    | { showReferrerMessage: false }
    | {
        showReferrerMessage: true
        referrerName: string
        referrerMessage: string
      }
}

export type BerbixTransactionModel = {
  refresh_token: string
  timestamp: string
  userId: Patient['oid']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submission?: Record<string, any>
}

export type BerbixTransaction = BerbixTransactionModel & DatabaseMetadata

export type ReengagementNoteModel = {
  eligible_for_re_engagement: 'yes' | 'no'
  employeeId: Employee['oid']
  patientId: Patient['oid']
  re_engagement_plan: string
  reason_for_re_engagement: string
  reengagementDate: string
  resume_with_prior_clinical_team: 'yes' | 'no'
  timestamp: string
  why_not_resume_with_prior_clinical_team: string
}

export type ReengagementNote = ReengagementNoteModel & DatabaseMetadata

export type IneligibleNoteModel = {
  employeeId: Employee['oid']
  patientId: Patient['oid']
  additonal_notes?: string
  ineligible_explanation?: string
  no_referral_request_explanation?: string
  not_interested_or_ready_to_begin?: string
  referral_request?: string
  referral_request_buprenorphine_clinic?: boolean
  referral_request_inpatient_program?: boolean
  referral_request_methadone_program?: boolean
  referral_request_other?: boolean
  referral_request_pcp?: boolean
  referral_request_mental_health_psychiatric?: boolean
  referral_request_residential_program?: boolean
  referral_request_intensive_outpatient_program?: boolean
  substance_user_or_psychiatric_ineligibility?: boolean
  substance_user_or_psychiatric_ineligibility_dsm_five?: boolean
  substance_user_or_psychiatric_ineligibility_medically_unstable?: boolean
  substance_user_or_psychiatric_ineligibility_other?: boolean
  substance_user_or_psychiatric_ineligibility_severe_polysubstance_use?: string[]
  timestamp?: string
  needs_transfer_hloc?: 'yes' | 'no'
  recommended_hloc?: 'residential' | 'inpatient' | 'intensive_outpatient'
  recommended_hloc_subcategory?:
    | 'clinically_managed_low_intensity_residential'
    | 'clinicially_managed_high_intensity_residential'
    | 'medically_managed_residential'
    | 'intensive_outpatient'
    | 'high_intensity_outpatient'
    | 'medically_managed_intensive_outpatient'
  referral_request_urgent?: 'yes' | 'no'
}

export type IneligibleNote = IneligibleNoteModel & DatabaseMetadata

/**
 * @deprecated
 * Support requests are no longer used.
 */
export type SupportRequestModel = {
  category: SupportRequestCategory
  employeeId: Employee['oid']
  message: string
  patientId: Patient['oid']
  timestamp: string
  severity?: SupportRequestSeverity
}

export type SupportRequest = SupportRequestModel & DatabaseMetadata

export type PriorAuthorizationModel = {
  drug?: string
  duration?: string
  patientId: Patient['oid']
  quantity?: string
  startDate?: string
  endDate?: string
  status?: 'active' | 'deleted'
}

export type PriorAuthorization = PriorAuthorizationModel & DatabaseMetadata

export type CptCodeModel = {
  CONSUMER_DESCRIPTION: string
  CPT_CODE: string
  FAC_TOTAL_RVU: string
  FULL_DESCRIPTION: string
  LONG_DESCRIPTION: string
  NF_TOTAL_RVU: string
  PLACE_HOLDER: string
  RESEQUENCED: string
  SEQ_ORDER: string
  SHORT_DESCRIPTION: string
  STATUS: string
}

export type CptCode = CptCodeModel & DatabaseMetadata

export type ChargeMasterModel = {
  cptCode: string
  medicareRate: number
}

export type ChargeMaster = ChargeMasterModel & DatabaseMetadata

export type TaskModel = {
  closedBy: string | null
  timestampMarkedAsClosed: string | null
  requestedById: Employee['oid']
  requestedByName: string
  status: 'completed' | 'not_completed'
  timestampCreated: string
  timestampMakredAsClosed: string
  taskTimezone?: string
  title: string
  type: 'prescription'
  details: {
    userId: Patient['oid']
    prescription: TaskPrescriptionData
  }
}

export type Task = TaskModel & DatabaseMetadata

export type ConfigsModel = {
  consultationQueue: {
    available: boolean
    sizeLimit: number
  }
  patientAppOutageBanner: {
    enabled: boolean
    message: string
  }
  emrAppOutageBanner: {
    enabled: boolean
    message: string
  }
  onCallEngineer: Employee['oid']
}

export type Configs = ConfigsModel & DatabaseMetadata

export type PatientReleaseOfInformationModel = (
  | ({
      recipientType: 'Organization'
      organizationContact?: string
    } & RequireAtLeastOne<{ fax: string; email: string }>)
  | ({ recipientType: 'Person' } & RequireAtLeastOne<{ fax: string; email: string }>)
  | { recipientType: 'Emergency contact' }
) &
  (
    | { status: Exclude<ReleaseOfInformationStatus, 'Active' | 'Revoked'> }
    | { status: 'Active'; activeDate: string }
    | { status: 'Revoked'; revokedOnDate: string }
  ) & {
    recipient: string
    contactInfo: {
      address: string
      address2?: string
      city: string
      state: StateName
      zipCode: string
      phone: string
    }
    canContactPhone: boolean
    infoTypes: string[]
    datesOfService: {
      start: string
      end: string
    }
    disclosurePurposes: string[]
    expirationDate: string
    signedOnDate: string
    signature: string
    changeRequests?: ReleaseOfInformationChangeRequest[]
    fileId?: PatientFile['oid']
  }

export type PatientReleaseOfInformation = PatientReleaseOfInformationModel & DatabaseMetadata

export type AchievementType = 'milestone' | 'badge'

export type AchievementModel<T extends AchievementType = AchievementType> = {
  // dateAchieved is the date when achievement was earned
  dateAchieved: ISOString
  // firstSeenAt is null until achievement has been viewed
  firstSeenAt: ISOString | null
  // We have two categories of achievement: milestones and badges
  type: T
  // We use the name to look up the rest of the context for each achievement
  name: T extends 'milestone' ? MilestoneType : T extends 'badge' ? BadgeType : never
}

export type Achievement<T extends AchievementType = AchievementType> = AchievementModel<T> &
  DatabaseMetadata

export type CaseReviewNoteModel = {
  visitId?: Appointment['id']
  content?: CaseReviewNoteContent
  patientId: Patient['oid']
  createdBy: string
  createdAt: string
  updatedAt?: string
  lockedAt?: string
  isLocked: boolean
  lockedBy?: Employee['oid']
  caseReviewTime?: string
  postCaseReviewTime?: string
  deleted: boolean
  // PC attestations
  isAttestedByPc?: boolean
  pcAttestation?: {
    attestedBy: Employee['oid']
    attestationComments: string
  }
  // Psych consultant attestations
  isAttested: boolean
  attestation?: {
    attestedBy: Employee['oid']
    attestationComments?: string
  }
  prescriptionIds?: number[]
}

export type CaseReviewNote = CaseReviewNoteModel & DatabaseMetadata

export type CocmTimeTrackingModel = {
  date: string
  createdAt: string
  updatedAt?: string
  totalMinutes: number
  employeeId: Employee['oid']
  patientId: Patient['oid']
  deleted: boolean
  activityType: CocmTimeTrackingActivities
}

export type CocmTimeTracking = CocmTimeTrackingModel & DatabaseMetadata

export const TOKEN_TYPES = [
  'otp',
  'checkout',
  'getStarted',
  'schedule',
  'token',
  'autoLogin',
  'passwordReset',
  'emailConfirmation',
  'firebaseCustomToken',
  'workflowSession',
  'financialConsent',
  'tpoConsent',
] as const

export type TokenType = (typeof TOKEN_TYPES)[number]

export type TokenModel = {
  payload: {
    email?: string
    phone?: string
    code?: string
    type?: TokenType
    token?: string
    userId?: Patient['oid']
    sessionId?: string
    appointmentId?: string
  }
  type: TokenType
  /** usedAt = Timestamp of when the token was first used */
  usedAt?: number
  /** retries = Amount of times the token has been requested to be sent */
  retries?: number
  /** attempts = Amount of times the token has been guessed incorrectly, if applicable */
  attempts?: number
  /**
   * In milliseconds, the point until which the token is valid.
   */
  validTo: number
}

export type Token = TokenModel & DatabaseMetadata

export type ConsentModel = {
  date: ISOString
  signature: string
} & (
  | {
      type: 'financial'
      version: FinancialConsentVersion
    }
  | {
      type: 'treatment'
      version: (typeof TREATMENT_CONSENT_VERSIONS)[number]
    }
  | {
      type: 'tpo'
      version: (typeof TPO_CONSENT_VERSIONS)[number]
    }
)

export type Consent = ConsentModel & DatabaseMetadata

export type InsuranceModel = {
  // Insurance data provided by the patient
  basicInsuranceData: InsuranceData | null
  // Insurance data from eligible once it has been verified
  eligibleData?: EligibleInsuranceData | null
  // Insurance data set as part of the manual verification process
  manuallyVerifiedData?: ManualInsuranceData | null
  // Set to true once the insurance has been verified and is found to be in network
  inNetwork: boolean
}
export type Insurance = InsuranceModel & DatabaseMetadata

export type ProblemModel = {
  code: string
  createdAt: string
  updatedAt?: string
  progression?: string
  chronicity?: string
  status?: string
  deactivatedAt?: string
}

export type Problem = ProblemModel & DatabaseMetadata

export type MentalHealthEvaluationModel = {
  createdAt: string
  patientId: Patient['oid']
  employeeId: Employee['oid']
  visitId: number
  diagnoses?: MentalHealthEvaluationDiagnosis[]
  isEvaluated: 'yes' | 'no' | null
  reasonForNoEvaluation?: ReasonForNoEvaluation | null
  reasonForNoEvaluationOther?: string | null
}

export type MentalHealthEvaluation = MentalHealthEvaluationModel & DatabaseMetadata

export type PaymentPlanModel = {
  patientId: Patient['oid']
  createdAt: ISOString
  updatedAt: ISOString
  canceledAt?: ISOString
  // The role of the person who created the payment plan (can be created in the EMR or patient portal)
  createdBy: 'patient' | 'employee'
  // If the payment plan was created by an employee, store their id here:
  employeeId?: Employee['oid']
  // We will impute the amount per payment by dividing the totalAmountInCents by the number of payments
  totalAmountInCents: number
  /*
   * The dates on which the cron will attempt to create a payment and charge the patient
   * the list is created when the payment plan is created.
   */
  paymentDates: YYYYMMDD[]
  // Stripe payment intent IDs that were created on each of the dates when the cron created a payment
  stripePaymentIntentIds: string[]
  /*
   * The frequency of the payment plan (weekly, biweekly, monthly). We could impute this but we show it on the front end
   * and it's easier to just store here
   */
  paymentFrequency: PaymentPlanFrequency
}

export type PaymentPlan = PaymentPlanModel & Oid

export type ConversationModel = {
  userId: User['uid']
  createdAt: ISOString
  active: boolean
  referrer: 'www' | 'community' | 'portal'
  /*
   * slackThreadTs is a unique identifier for a Slack message. In this case, we're using it to identify a Slack thread
   * that we want to add messages to.
   */
  slackThreadTs?: string
}

export type Conversation = ConversationModel & DatabaseMetadata

export type MessageModel = {
  message: string
  createdAt: string
  userType: 'user' | 'employee'
  /*
   * slackTs is a unique identifier for a Slack message. In this case, we're using it to identify a Slack message
   * that we want to update. It gets updated on this model after the message is sent to Slack, therefore it is
   * technically optional here.
   */
  slackTs: string | null
}

export type Message = MessageModel & DatabaseMetadata
