import { useForm } from '@mantine/form'
import {
  Checkbox,
  LinkText,
  List,
  ListItem,
  PrimaryButton,
  Stack,
  Text,
  TextInput,
  TitleTwo,
  useLifecycle,
  validateWith,
} from '@shared/components'
import React from 'react'
import { ContactSupport } from '../common/components'
import { isAtleastOneWord, isRequired } from '../common/forms'
import * as FullStory from '../common/fullstory'
import { usePortalDims, usePortalMutation } from '../common/hooks'
import { TpoConsentV1 } from '../documents/TpoConsentV1'

type Props = {
  redirectUrl: string
  token: string
}

const TpoConsent = ({ redirectUrl, token }: Props) => {
  const { isMobile } = usePortalDims()
  const [checked, setChecked] = React.useState(false)

  const form = useForm({
    initialValues: {
      signature: '',
    },
    validate: {
      signature: validateWith(isRequired, isAtleastOneWord),
    },
  })

  const signMutation = usePortalMutation('POST /consents/tpo', {
    // Do not block the patient from proceeding to visit, even if the mutation fails.
    onSettled: (_data, error) => {
      FullStory.event('Financial Consent Signed', {
        redirectUrl,
        isError: String(Boolean(error)),
      })

      window.location.replace(redirectUrl)
    },
  })

  const onSign = () => {
    if (form.validate().hasErrors) {
      return
    }

    signMutation.mutate({
      data: {
        token,
        signature: form.values.signature,
        version: 1,
      },
    })
  }

  return (
    <Stack>
      <TitleTwo>
        Consent to Exchange Information for Treatment, Payment, and Healthcare Operations Purposes
      </TitleTwo>
      <Stack spacing='lg'>
        <TpoConsentV1 />
        <Checkbox
          onClick={() => setChecked(!checked)}
          checked={checked}
          label='I have read, understand, and agree to this Consent to Exchange Information for Treatment, Payment, and Healthcare Operations Purposes'
        />
        <TextInput
          {...form.getInputProps('signature')}
          label='Signed by'
          placeholder='Type your full legal name'
          maxLength={64}
          disabled={signMutation.isLoading}
        />
        <PrimaryButton
          fullWidth={isMobile}
          onClick={onSign}
          disabled={!checked}
          loading={signMutation.isLoading}
        >
          Sign and agree
        </PrimaryButton>
      </Stack>
    </Stack>
  )
}

export const TpoConsentBeforeZoom = ({ redirectUrl, token }: Props) => {
  const { isMobile } = usePortalDims()
  const [showAgreement, setShowAgreement] = React.useState(false)

  useLifecycle({
    onMount: () => {
      FullStory.event('TPO Consent Before Zoom', { redirectUrl })
    },
  })

  if (showAgreement) {
    return <TpoConsent redirectUrl={redirectUrl} token={token} />
  }

  return (
    <Stack align={isMobile ? 'center' : 'start'} spacing='lg'>
      <TitleTwo>Please sign the following agreement before your appointment</TitleTwo>
      <Text>Your clinician is informed and will be waiting for you.</Text>
      <Text>
        The Consent to Exchange Information for Treatment, Payment, and Healthcare Operations
        Purposes allows Ophelia to perform tasks like:
      </Text>
      <List>
        <ListItem>coordinating care with your other medical care providers,</ListItem>
        <ListItem>
          sharing information with the pharmacy you choose, and your insurance, and
        </ListItem>
        <ListItem>carrying out other operational functions like processing payments.</ListItem>
      </List>
      <Text>
        This Consent does not change your right to privacy and our commitment to protecting your
        information and is only used for purposes related to treatment, payment and operations. If
        you have questions about what this includes, detailed information is available on the{' '}
        <LinkText
          component='a'
          href='https://www.hhs.gov/hipaa/for-professionals/privacy/guidance/disclosures-treatment-payment-health-care-operations/index.html'
        >
          Department of Health and Human Services website.
        </LinkText>
      </Text>
      <PrimaryButton
        fullWidth={isMobile}
        onClick={() => {
          setShowAgreement(true)
        }}
      >
        Review & sign
      </PrimaryButton>
      <ContactSupport />
    </Stack>
  )
}
