import {
  ArrowLeftIcon,
  Box,
  Group,
  PhoneInput,
  PrimaryButton,
  ResponsiveBox,
  Space,
  Stack,
  TertiaryButton,
  Text,
  TitleThree,
  TitleTwo,
  emojiMap,
  useLifecycle,
} from '@shared/components'
import { getOpheliaHttpError } from '@shared/types'
import React from 'react'
import { Link } from 'react-router-dom'
import { SigninProps } from '.'
import { ContactSupport } from '../../../common/components'
import { setUserVars } from '../../../common/fullstory'
import { routes } from '../../../common/routes'
import { sendPageEvent } from '../../../common/rudderstack'

export const Phone = ({ form, setCurrentStep, signInWithPhoneNumber }: SigninProps) => {
  const { error: phoneInputError, ...phoneInputProps } = form.getInputProps('phoneNumber')

  const onSubmit = () => {
    signInWithPhoneNumber.reset()
    setUserVars({ phoneUsedForLogin: form.values.phoneNumber })

    if (!form.validateField('phoneNumber').hasError) {
      signInWithPhoneNumber.mutate(form.values.phoneNumber, {
        onSuccess: () => setCurrentStep('verification'),
      })
    }
  }

  useLifecycle({
    onMount: () => sendPageEvent('Phone Collection'),
  })

  return (
    <Box test-id='content:phone-number' component='div'>
      <Stack spacing='lg'>
        <Stack spacing='xs'>
          <TitleTwo>{emojiMap['waving hand']} Welcome to myOphelia</TitleTwo>
          <Space h='md' />
          <TitleThree>Sign in with your phone number</TitleThree>
          <Text>We&apos;ll text you a code to confirm your account.</Text>
        </Stack>
        <PhoneInput
          test-id='input:phone-number'
          label='Phone number'
          error={
            phoneInputError ||
            getOpheliaHttpError(signInWithPhoneNumber.error, 'Something went wrong')
          }
          {...phoneInputProps}
        />
        <ResponsiveBox
          mobile={
            <Stack spacing='lg'>
              <PrimaryButton
                test-id='button:submit@mobile'
                fullWidth
                onClick={onSubmit}
                loading={signInWithPhoneNumber.isLoading}
              >
                Sign in
              </PrimaryButton>
              <TertiaryButton
                fullWidth
                test-id='button:get-started@mobile'
                component={Link}
                leftIcon={<ArrowLeftIcon />}
                to={routes.welcome.index}
              >
                Get started
              </TertiaryButton>
              <ContactSupport />
            </Stack>
          }
          desktop={
            <Group position='apart'>
              <TertiaryButton
                test-id='button:get-started@desktop'
                component={Link}
                leftIcon={<ArrowLeftIcon />}
                to={routes.welcome.index}
              >
                Get started
              </TertiaryButton>
              <PrimaryButton
                test-id='button:submit@desktop'
                onClick={onSubmit}
                loading={signInWithPhoneNumber.isLoading}
              >
                Sign in
              </PrimaryButton>
            </Group>
          }
        />
      </Stack>
    </Box>
  )
}
