import { autoUpdate, computePosition } from '@floating-ui/dom'
import { MentionOptions } from '@tiptap/extension-mention'
import { PluginKey } from '@tiptap/pm/state'
import { ReactRenderer } from '@tiptap/react'
import { SmartPhrase } from '../..'
import { SmartPhraseList, SmartPhraseListForwardRef } from './SmartPhraseList'

export const smartPhrasesSuggestions = (
  smartPhrases: SmartPhrase[],
): MentionOptions['suggestion'] => {
  return {
    char: '#',
    pluginKey: new PluginKey('smartPhrase'),
    items: ({ query }: { query: string }) => {
      return smartPhrases.filter(item => item.label.toLowerCase().includes(query.toLowerCase()))
    },
    command: props => {
      // This removes the # sign after a phrase is selected
      props.editor.commands.insertContentAt(
        { from: props.range.from, to: props.range.to },
        props.props.text,
      )
    },
    render: () => {
      let reactRenderer: ReactRenderer<SmartPhraseListForwardRef>
      let cleanup: (() => void) | undefined

      return {
        onStart(props) {
          if (!props.clientRect) {
            return
          }

          const handleClose = () => {
            reactRenderer?.updateProps({ ...props, hide: true })
          }

          reactRenderer = new ReactRenderer(SmartPhraseList, {
            props: { ...props, handleClose },
            editor: props.editor,
          })
          const htmlElement = reactRenderer.element as HTMLElement
          // Add the element to the DOM, floating-ui will set the styling
          document.body.appendChild(htmlElement)

          const virtualElement = {
            getBoundingClientRect() {
              return props.clientRect?.() || new DOMRect(0, 0)
            },
          }

          cleanup = autoUpdate(virtualElement, htmlElement, () => {
            /*
             * TODO: this component would be more readable and consistent as an element
             * above the text editor rather than following the cursor
             */
            void computePosition(virtualElement, htmlElement, { placement: 'top-start' }).then(
              ({ x, y }) => {
                Object.assign(htmlElement.style, {
                  left: `${x}px`,
                  top: `${y}px`,
                  position: 'absolute',
                  zIndex: 300,
                })
              },
            )
          })
        },

        onUpdate(props) {
          reactRenderer?.updateProps(props)
        },

        onKeyDown(props) {
          if (props.event.key === 'Escape') {
            props.event.stopPropagation()
            reactRenderer?.updateProps({ ...props, hide: true })
            return true
          }

          if (reactRenderer?.ref) {
            return reactRenderer.ref.onKeyDown(props)
          }

          return false
        },

        onExit() {
          try {
            cleanup?.()
            if (reactRenderer) {
              document.body.removeChild(reactRenderer.element)
              reactRenderer.destroy()
            }
          } catch {
            // If cannot remove, it's because it doesn't exist
          }
        },
      }
    },
  }
}
