import { useEffect } from 'react';
import arePassiveEventsSupported from 'are-passive-events-supported';
import useLatest from 'use-latest';

var MOUSEDOWN = 'mousedown';
var TOUCHSTART = 'touchstart';
var events = [MOUSEDOWN, TOUCHSTART];

var getAddOptions = function getAddOptions(event) {
  if (event === TOUCHSTART && arePassiveEventsSupported()) {
    return {
      passive: true
    };
  }
};

var currentDocument = document ;
function useOnClickOutside(ref, handler, _temp) {
  var _ref = _temp === void 0 ? {} : _temp,
      _ref$document = _ref.document,
      document = _ref$document === void 0 ? currentDocument : _ref$document;

  var handlerRef = useLatest(handler);
  useEffect(function () {
    if (!handler) {
      return;
    }

    var listener = function listener(event) {
      if (!ref.current || !handlerRef.current || ref.current.contains(event.target)) {
        return;
      }

      handlerRef.current(event);
    };

    events.forEach(function (event) {
      document.addEventListener(event, listener, getAddOptions(event));
    });
    return function () {
      events.forEach(function (event) {
        document.removeEventListener(event, listener);
      });
    };
  }, [!handler]);
}

export { useOnClickOutside as default };
