import { Stack, useLifecycle } from '@shared/components'
import {
  GetNextWorkflowStateRequest,
  GetNextWorkflowStateResponse,
  PromptResponsePayload,
  Prompt as PromptType,
  WorkflowProgress,
  WorkflowSessionMetadata,
  WorkflowSessionStatus,
  WorkflowType,
  WorkflowVariant,
  getOpheliaHttpError,
  isGetNextWorkflowStateRequest,
} from '@shared/types'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Prompt, SessionProgress, Skeletons } from '..'
import { patientApi } from '../../api'
import { useLDClient } from '../../hooks'
import { logger } from '../../logger'
import { convertContextKeyToPageEventFormat, sendPageEvent } from '../../rudderstack'

export type WorkflowApi =
  | 'workflow'
  | 'referral'
  | 'referral-with-patient'
  | 'wellness'
  | 'not_ready_flow'

export type WorkflowParams = { sessionId: string; promptId: string }

const getWorkflowMethods = (
  workflowApi: WorkflowApi,
  {
    sessionId = '',
    promptId = '',
    token = '',
  }: { sessionId: string | undefined; promptId: string | undefined; token: string | undefined },
) => {
  if (workflowApi === 'wellness') {
    return {
      getWorkflowState: patientApi.getMutation('POST /wellness-check/workflow/:by/:value'),
      getPrompt: patientApi.getQuery(
        'GET /wellness-check/workflow/session/:sessionId/prompt/:promptId',
        {
          params: { sessionId, promptId },
          query: { token },
        },
      ),
    }
  }

  if (['referral', 'referral-with-patient'].includes(workflowApi)) {
    return {
      getWorkflowState: patientApi.getMutation('POST /referral/workflow/:by/:value'),
      getPrompt: patientApi.getQuery('GET /referral/workflow/session/:sessionId/prompt/:promptId', {
        params: { sessionId, promptId },
      }),
    }
  }

  if (workflowApi === 'not_ready_flow') {
    return {
      getWorkflowState: patientApi.getMutation('POST /not-ready/workflow/:by/:value'),
      getPrompt: patientApi.getQuery(
        'GET /not-ready/workflow/session/:sessionId/prompt/:promptId',
        {
          params: { sessionId, promptId },
        },
      ),
    }
  }

  return {
    getWorkflowState: patientApi.getMutation('POST /workflow/:by/:value'),
    getPrompt: patientApi.getQuery('GET /workflow/session/:sessionId/prompt/:promptId', {
      params: { sessionId, promptId },
    }),
  }
}

export const WorkflowPrompt = ({
  prefix,
  onComplete,
  onError,
  workflowType,
  showProgress = true,
  showProgressNumbers = true,
  showBackButton = false,
  token,
  workflowApi,
  onGetSessionId,
  metadata,
  workflowVariant,
}: {
  prefix: string
  onComplete: (finalPromptResponseKey?: string) => void
  onError?: (error: string) => void
  workflowType: WorkflowType
  showProgress?: boolean
  showProgressNumbers?: boolean
  showBackButton?: boolean
  token?: string
  workflowApi: WorkflowApi
  onGetSessionId?: (sessionId: string) => void
  metadata?: WorkflowSessionMetadata | undefined
  workflowVariant?: WorkflowVariant | undefined
}) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { sessionId, promptId } = useParams<WorkflowParams>()
  const [prompt, setPrompt] = useState<PromptType>()
  const [response, setResponse] = useState<PromptResponsePayload>()
  const [status, setStatus] = useState<WorkflowSessionStatus>()
  const [progress, setProgress] = useState<WorkflowProgress>()
  const { ldClient } = useLDClient()

  const { getWorkflowState, getPrompt } = getWorkflowMethods(workflowApi, {
    sessionId,
    promptId,
    token,
  })

  const nextWorkflowState = useMutation<
    GetNextWorkflowStateResponse,
    unknown,
    {
      params: GetNextWorkflowStateRequest['params']
      data: GetNextWorkflowStateRequest['body']
    }
  >(getWorkflowState, {
    onSuccess: (data, variables) => {
      const request = {
        params: variables?.params,
        body: variables?.data,
      } as GetNextWorkflowStateRequest

      if (request.params.by === 'type') {
        onGetSessionId?.(data.sessionId)
      }

      setStatus(data.status)

      if (data.status === 'complete') {
        if (request.params.by === 'session') {
          onComplete(data.redirect)
        } else {
          onComplete()
        }
      } else {
        setPrompt(data.prompt)
        setProgress(data.progress)
        if (data.status === 'in_progress') {
          setResponse(data.response?.payload)
        }

        sendPageEvent(convertContextKeyToPageEventFormat(data.prompt.contextKey))

        logger.info(`Current Prompt ${data.prompt.contextKey}`, {
          tags: { workflow: workflowType },
        })

        navigate(
          {
            pathname: `${prefix}/${data.sessionId}/prompt/${data.prompt.oid}`,
            // Carry over any search params - this is for the wellness check workflow that has tokens, etc.
            search: searchParams.toString(),
          },
          {
            // Replace any placeholder / bad routes
            replace:
              (isGetNextWorkflowStateRequest(request, 'session') && !request.body.promptId) ||
              request.params.by === 'type',
          },
        )
      }
    },
    onError: error => {
      onError?.(getOpheliaHttpError(error, 'Something went wrong, please try again'))
    },
  })

  useLifecycle({
    onMount: () => {
      if (!sessionId) {
        // If sessionId is missing it's because the user is starting the workflow.
        nextWorkflowState.mutate({
          params: { by: 'type', value: workflowType },
          data: { metadata, variant: workflowVariant },
        })
      } else if (!promptId) {
        if (ldClient) {
          ldClient?.track('Onboarding Workflow Started')
        }
        // If promptId is missing it means the user needs the first prompt.
        nextWorkflowState.mutate({
          params: { by: 'session', value: sessionId },
          data: { token },
        })
      }
    },
  })

  const onSubmit = (
    payload: PromptResponsePayload,
    { onError = () => void 0 }: { onError?: () => void } = {},
  ) => {
    if (sessionId && promptId) {
      nextWorkflowState.mutate(
        {
          params: { by: 'session', value: sessionId },
          data: {
            promptId,
            response: payload,
            token,
          },
        },
        { onError },
      )
    }
  }

  const workflowPrompt = useQuery(getPrompt[0], getPrompt[1], {
    onSuccess: data => {
      setStatus(data.status)
      if (data.status === 'complete') {
        onComplete()
      } else {
        setPrompt(data.prompt)
        if (data.status === 'in_progress') {
          setResponse(data.response?.payload)
        }
        setProgress(data.progress)
        sendPageEvent(convertContextKeyToPageEventFormat(data.prompt.contextKey))
      }
    },
    onError: error => {
      onError?.(getOpheliaHttpError(error, 'Something went wrong, please try again'))
    },
    // This prevents the current/total numbers from blinking to zero between prompt loads.
    keepPreviousData: true,
    enabled:
      Boolean(sessionId) &&
      Boolean(promptId) &&
      !nextWorkflowState.isLoading &&
      status !== 'complete',
  })

  const isLoading = nextWorkflowState.isLoading || workflowPrompt.isFetching

  return (
    <Stack spacing='lg'>
      {showProgress && (
        <SessionProgress
          showProgressNumbers={showProgressNumbers}
          current={progress?.current || 0}
          total={progress?.total || 0}
          isLoading={isLoading}
        />
      )}
      {isLoading && <Skeletons />}
      {!isLoading && prompt && prompt?.oid === promptId && (
        <Prompt
          prompt={prompt}
          response={response}
          onSubmit={onSubmit}
          showBackButton={showBackButton}
        />
      )}
    </Stack>
  )
}
