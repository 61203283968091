import { ApiSchemas, OpheliaApiDefinitionFromSchemas } from '../../utils'
import { ConsultationQueueSchemas } from './consultationQueue'
import { DropInClinicRouteSchemas } from './dropInClinic'
import { EmrTasksRouteSchemas } from './emrTasks'
import { EncountersRouteSchemas } from './encounters'
import { CommunicationsRouteSchemas } from './externalCommunications'
import { EmrInsurancePayersApi } from './insurance/payers'
import { InsuranceNotesRouteSchemas } from './insuranceNotes'
import { NoteRouteSchemas } from './notes'
import { PatientFilesRouteSchemas } from './patientFiles'
import { PatientSettingsRouteSchemas } from './patientSettings'
import { PatientRouteSchemas } from './patients'
import { PayerConfigsRouteSchemas } from './payerConfigs'
import { SchedulingRouteSchemas } from './scheduling'

export const EmrLunaSchemas = {
  ...ConsultationQueueSchemas,
  ...PatientSettingsRouteSchemas,
  ...PayerConfigsRouteSchemas,
  ...DropInClinicRouteSchemas,
  ...EmrTasksRouteSchemas,
  ...SchedulingRouteSchemas,
  ...NoteRouteSchemas,
  ...EmrInsurancePayersApi,
  ...CommunicationsRouteSchemas,
  ...EncountersRouteSchemas,
  ...PatientRouteSchemas,
  ...InsuranceNotesRouteSchemas,
  ...PatientFilesRouteSchemas,
  // ...Add other schemas here
} satisfies ApiSchemas

export type EmrLunaApi = OpheliaApiDefinitionFromSchemas<typeof EmrLunaSchemas>
