import {
  AuthenticationMethod,
  CurrentUserPatient,
  Statuses,
  UpdateMeRequestBody,
} from '@shared/types'
import { toTime } from '@shared/utils'
import cookie from 'cookie'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { UseQueryResult, useMutation, useQuery, useQueryClient } from 'react-query'
import { authApi, currentUserApi, myAuthApi } from '../api'
import { cio } from '../customerio'
import { FirebaseUser, auth } from '../firebase'
import { setUserVars } from '../fullstory'
import { VISITED_MY_OPHELIA, analytics, sendIdentifyEvent } from '../rudderstack'
import { clearSessionStorage } from '../storage'
import { getSessionData } from '../userSession'
import { useQueryParams } from './use-query-params'

type Context = {
  isLoading: boolean
  isAuthenticated: boolean
  isAuthorized: boolean
  authenticationMethod: AuthenticationMethod
  hasPassword: boolean
  hasEmail: boolean
  firebaseUser: FirebaseUser | null | undefined
  currentUser: UseQueryResult<CurrentUserPatient> | undefined
}

const initialContext: Context = {
  isLoading: true,
  isAuthenticated: false,
  isAuthorized: false,
  authenticationMethod: undefined,
  hasPassword: false,
  hasEmail: false,
  firebaseUser: undefined,
  currentUser: undefined,
}

const AuthContext = createContext<Context>(initialContext)

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [firebaseUser, setFirebaseUser] = useState<Context['firebaseUser']>(undefined)
  const queryClient = useQueryClient()

  const updateCurrentUser = useMutation(currentUserApi.update, { retry: 3 })

  const isAuthenticated = Boolean(firebaseUser)
  const currentUserQuery = useQuery(
    ['currentUserApi.retrieve', firebaseUser?.uid],
    currentUserApi.retrieve,
    {
      // Total wait time for request to succeed is 15 seconds
      retry: 5,
      retryDelay: failureCount => {
        const delayMultiplier = 1.5
        return toTime(`${failureCount * delayMultiplier} sec`).ms()
      },
      enabled: isAuthenticated,
      onSuccess: async data => {
        setUserVars(data)
        const nextSession = await getSessionData()
        const currentPatientStatus = data.statuses.patient
        const nextStatuses: Statuses = { patient: currentPatientStatus }
        const { gclid } = cookie.parse(document.cookie)

        const updateMeRequestBody: UpdateMeRequestBody = {
          lastSession: nextSession,
          statuses: nextStatuses,
        }

        /**
         * A patient must always have a status and are therefore initialized with a status of 'new'.
         * However, as soon as a patient enters their first user session that status must transition to 'lead'.
         */
        if (currentPatientStatus === 'new') {
          nextStatuses.patient = 'lead'
        }

        // Only update gclid if patient doesn't have one and the cookie is defined
        if (!data.attributes?.gclid && gclid) {
          updateMeRequestBody.attributes = { gclid }
        }

        updateCurrentUser.mutate(updateMeRequestBody)
      },
    },
  )

  const query = useQueryParams()
  const impersonationToken = query.get('impersonationToken')
  const signInFirebaseAuth = useMutation(authApi.signInWithToken, {
    onSuccess: () => {
      void queryClient.invalidateQueries(['authApi.getTokenAuthenticationMethod'])
    },
  })

  const signInMethodQuery = useQuery(
    [`${signInFirebaseAuth.status}.${firebaseUser?.refreshToken}`],
    authApi.getTokenAuthenticationMethod,
    {
      enabled: isAuthenticated,
    },
  )

  useEffect(() => {
    if (impersonationToken && !signInFirebaseAuth.isLoading) {
      /*
       * If this is impersonation, we don't want to maintain any state in the browser storage
       * from a previous impersonation session
       */
      clearSessionStorage()
      query.delete('impersonationToken')
      signInFirebaseAuth.mutate(impersonationToken)
    }
  }, [impersonationToken, query, signInFirebaseAuth])

  const hasPassword = Boolean(currentUserQuery.data?.account?.hasPassword)
  const hasEmail = Boolean(currentUserQuery.data?.personalData?.email)
  const authenticationMethod = signInMethodQuery.data
  const isAuthorized = Boolean(currentUserQuery.data)

  const isLoading =
    firebaseUser === undefined || currentUserQuery.isFetching || signInMethodQuery.isFetching

  useEffect(
    () =>
      auth.onIdTokenChanged(async nextFirebaseUser => {
        /*
         * We allow anonymous sign in for the chatbox to work.
         * However, that anonymous user is insufficient for the rest of the app.
         */
        if (nextFirebaseUser && !nextFirebaseUser.isAnonymous) {
          authApi.firebaseUser = nextFirebaseUser
          myAuthApi.firebaseUser = nextFirebaseUser
          setFirebaseUser(nextFirebaseUser)
        } else {
          if (firebaseUser) {
            await queryClient.cancelMutations()
            await queryClient.cancelQueries()
            queryClient.clear()
            sessionStorage.clear()
          }

          authApi.firebaseUser = null
          myAuthApi.firebaseUser = null
          setFirebaseUser(null)
        }
      }),
    [firebaseUser, queryClient],
  )

  /*
   * We separate this from the above useEffect as the above can run multiple
   * times, while we only want to identify once.
   */
  useEffect(() => {
    if (firebaseUser?.uid) {
      if (firebaseUser.uid !== analytics.getUserId()) {
        sendIdentifyEvent({
          uid: firebaseUser.uid,
          /*
           * This is necessary since once a patient goes from an unauthenticated in rudderstack (no uid) to
           * authenticated, user traits are cleared out.
           */
          data: { [VISITED_MY_OPHELIA]: true },
        })
      }
      cio.identify({ id: firebaseUser.uid })
    }
  }, [firebaseUser])

  const context: Context = {
    isLoading,
    isAuthenticated,
    isAuthorized,
    authenticationMethod,
    hasPassword,
    hasEmail,
    firebaseUser,
    currentUser: currentUserQuery,
  }

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
